<template>
  <div>
    <v-simple-table fixed-header>
      <template v-if="loading">
        <Load :loading-msg="loadingMsg" />
      </template>
      <div
        v-else
        class="mr-5 ml-5"
      >
        <v-data-table
          :headers="headers"
          :items="contracts"
          :items-per-page="5"
          loading-text="carregando lista"
          no-data-text="Nenhum contrato encontrado"
          item-key="id"
          @click:row="goToContract($event)"
        >
          <template
            #item.starts_at="{ item }"
          >
            {{ returnData(item.starts_at) }}
          </template>
          <template
            #item.expires_at="{ item }"
          >
            {{ dayjs(item.expires_at).format('DD/MM/YYYY') }}
          </template>
          <template
            #item.renews_at="{ item }"
          >
            {{ dayjs(item.renews_at).format('DD/MM/YYYY') }}
          </template>
        </v-data-table>
      </div>
    </v-simple-table>
  </div>
</template>

<script>
import utils from '@/api/utils.js'
import Load from '@/components/Loading.vue'
import dayjs from '@/dayjs'

export default {
  components: {
    Load
  },
  props: {
    idschool: { type: Number, default: 0 }
  },
  data: () => ({
    loading: true,
    loadingMsg: 'carregando',
    contracts: [],
    gradeLevelGroups: [],
    teachingSystems: [],
    headers: [
      {
        text: 'Inicio',
        align: 'center',
        value: 'starts_at'
      },
      {
        text: 'Final',
        align: 'center',
        value: 'expires_at'
      },
      {
        text: 'Renovação',
        align: 'center',
        value: 'renews_at'
      }
    ]
  }),
  async created () {
    if (this.idschool > 0) {
      await this.getContracts()
      await this.getGradeLevelGroups()
      await this.getTeachingSystems()
    }
  },
  methods: {
    async getContracts () {
      try {
        this.contracts = await this.$api.getContracts(this.idschool)
        this.loading = false
      } catch (e) {
        // console.log(e.response)
        this.loading = false
      }
    },
    async getTeachingSystems () {
      try {
        this.teachingSystems = await this.$api.getTeachingSystems()
        this.$emit('teachingSystems', this.teachingSystems)
      } catch (e) {
        // console.log(e.response)
      }
    },
    async getGradeLevelGroups () {
      try {
        this.gradeLevelGroups = await this.$api.getGradeLevelGroups()
        utils.setGradeLevels(this.gradeLevelGroups.flatMap((item) => item.grade_levels))
        this.$emit('gradeLevelGroups', this.gradeLevelGroups)
      } catch (e) {
        // console.log(e.response)
      }
    },
    returnData (data) {
      return dayjs(data).format('DD-MM-YYYY')
    },
    goToContract (contract) {
      this.$emit('goTo', contract)
    },

    dayjs
  }
}
</script>
