<template>
  <v-app style="background-color: #dee2e6">
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        class="text-center"
        justify="center"
      >
        <slot />
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {

}
</script>
