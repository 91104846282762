<template>
  <div>
    <v-card-title>
      Excluir {{ title }}
    </v-card-title>
    <v-card-text>
      <v-form
        ref="form"
      >
        Tem certeza que deseja excluir {{ title }}, {{ name }} ?
      </v-form>
    </v-card-text>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <v-btn
          color="primary"
          text
          @click="close()"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="onDelete()"
        >
          Excluir
        </v-btn>
      </div>
    </v-card-text>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    title: { type: String, default: undefined },
    name: { type: String, default: undefined }
  },
  data: () => ({
    // name: ''
  }),
  created () {
  },
  methods: {
    close () {
      this.$emit('close')
    },

    onDelete () {
      this.$emit('confirm')
    }
  }
}
</script>
