const MediaContents = () => import('@/views/contents/Content_Media.vue')
const MediaContent = () => import('@/views/contents/Content_Media_id.vue')
const Textbooks = () => import('@/views/settings/Material_text_books.vue')
const Textbook = () => import('@/views/settings/Material_text_book_id.vue')
const NewMaterial = () => import('@/views/settings/New_Material.vue')
const Articles = () => import('@/views/settings/articles/Articles.vue')
const Article = () => import('@/views/settings/articles/Article_id.vue')
const NewArticle = () => import('@/views/settings/articles/New_Article.vue')
const Questions = () => import('@/views/contents/questions/Questions.vue')
const QuestionEditor = () => import('@/views/contents/questions/QuestionEditor.vue')
const CreateQuestion = () => import('@/views/contents/questions/CreateQuestion.vue')
const Question = () => import('@/views/contents/questions/Question.vue')

export default [
  {
    path: '/midias',
    name: 'contents.media',
    component: MediaContents
  },
  {
    path: '/conteudos/questoes',
    name: 'contents.questions',
    component: Questions
  },
  {
    path: '/conteudos/questoes/criar',
    name: 'questions.create',
    component: CreateQuestion
  },
  {
    path: '/conteudos/questoes/:id',
    name: 'questions.view',
    component: Question
  },
  {
    path: '/conteudos/questoes/editar/:id',
    name: 'questions.editor',
    component: QuestionEditor
  },
  {
    path: '/midia/:id',
    name: 'contents-media-id',
    component: MediaContent
  },
  {
    path: '/materiais',
    name: 'materials',
    component: Textbooks
  },
  {
    path: '/materiais/novo',
    name: 'new-material',
    component: NewMaterial
  },
  {
    path: '/material/:id',
    name: 'material-id',
    component: Textbook
  },
  {
    path: '/artigos',
    name: 'articles',
    component: Articles
  },
  {
    path: '/artigos/novo',
    name: 'new-article',
    component: NewArticle
  },
  {
    path: '/artigos/:id',
    name: 'article-id',
    component: Article
  }
]
