import axios from 'axios'

// Subjects

export async function getSubjects () {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/subjects?include_disabled=true')
  return response.data.data
}

export async function newSubject (data) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/subjects', data)
  return response.data.data
}

export async function getSubject (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/subjects/' + id)
  return response.data.data
}

export async function updateSubject (id, data) {
  const response = await axios.put(import.meta.env.VITE_API_URL + '/v1/intranet/subjects/' + id, data)
  return response.data.data
}

export async function disableSubject (id) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/subjects/' + id + '/disable')
  return response.data.data
}

export async function getTopicsOfSubject (subjectID, gradeLevelID, teachingSystemID) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/subjects/' + subjectID + '/topics?grade_level_id=' + gradeLevelID + '&teaching_system_id=' + teachingSystemID)
  return response.data.data
}

export default {
  getSubjects,
  newSubject,
  getSubject,
  updateSubject,
  disableSubject,
  getTopicsOfSubject
}
