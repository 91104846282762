<template>
  <div>
    <component
      :is="layout + '-layout'"
      v-if="layout !==''"
    >
      <router-view />
      <SnackBar />
    </component>
    <v-dialog
      id="modal-error"
      v-model="modalError"
      color="white"
      width="500"
      title="Ops..."
    >
      <v-card>
        <v-card-text>
          <v-card-title>
            Ops..
          </v-card-title>
          {{ modalMsg ? modalMsg: 'Ocorreu um Erro na pagina, tente novamente mais tarde' }}
          <template v-if="modalMsgOK">
            <v-btn
              color="primary"
              class="mt-3"
              block
              @click="closeError()"
            >
              Pagina anterior
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              color="primary"
              class="mt-3"
              block
              @click="[modalError=false, $store.commit('setError', null)]"
            >
              OK
            </v-btn>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DefaultLayout from '@/layouts/Default.vue'
import AuthLayout from './layouts/Auth.vue'
import SnackBar from '@/components/Snack_bar.vue'

export default {
  components: {
    DefaultLayout,
    AuthLayout,
    SnackBar
  },

  data: () => ({
    modalError: false,
    modalMsg: null,
    modalMsgOK: true,
    render: 0
  }),

  computed: {
    layout () {
      return this.$store.state.layout
    }
  },
  errorCaptured (err) {
    console.log(err)
    this.modalMsg = null
    this.modalMsgOK = true
    if (this.$store.state.error.status === true) {
      this.modalMsg = this.$store.state.error.msg
      this.modalMsgOK = false
    }
    if (err.data) {
      if (err.data.message) {
        this.modalMsg = err.data.message
        this.modalMsgOK = false
      }
    }
    this.modalError = true
    return false
  },
  methods: {
    closeError () {
      this.$store.commit('setError', null)
      this.modalError = false
      this.$router.back()
    }
  }
}
</script>
