const AudeExams = () => import('@/views/audeExams/AudeExams.vue') // List, Create, Update, Delete
const AudeExam = () => import('@/views/audeExams/AudeExam.vue') // List, Create, Update, Delete
const AudeExamQuestionGroup = () => import('@/views/audeExams/AudeExamQuestionGroup.vue') // List
const AudeExamQuestionEditor = () => import('@/views/audeExams/AudeExamQuestionEditor.vue') // Show, Create, Update, Delete

export default [
  {
    // Cria, Lista
    path: '/Avaliacao-AUDE',
    name: 'aude-exams',
    component: AudeExams
  },
  { // exibe, atualiza, deleta, publica + cria, lista (grupos de questoes)
    path: '/Avaliacao-AUDE/:id',
    name: 'aude-exam.id',
    component: AudeExam
  },
  { // exibe, atualiza, deleta
    path: '/Avaliacao-AUDE/grupo-de-questoes/:id/:status/:gradelevel',
    name: 'aude-exam.question-group-id',
    component: AudeExamQuestionGroup
  },
  {
    path: '/Avaliacao-AUDE/grupo-de-questoes/:idgroup/:status/:gradelevel/questao/:id?',
    name: 'aude-exam.question-editor',
    component: AudeExamQuestionEditor
  }
]
