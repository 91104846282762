<template>
  <div>
    <template v-if="loading">
      <Load :loading-msg="loadingMsg" />
    </template>
    <template v-else>
      <template v-if="showInfo">
        <v-card-title>
          Enviar nova foto
          <v-spacer />
          <v-btn
            icon
            @click="close()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-file-input
          ref="file"
          v-model="files"
          accept=".png, .jpeg, .jpg"
          label="Enviar foto"
          :error-messages="msgSnackbar"
          @change="changeBtn"
        />
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="d-flex align-end"
            text="text"
            color="primary"
            :disabled="btnFile"
            @click="sendAvatar"
          >
            <v-icon>
              mdi-plus
            </v-icon>
            Adicionar
          </v-btn>
        </v-card-actions>
      </template>
      <template v-if="delAvatar">
        <v-card-title>
          Excluir seu avatar?
          <v-spacer />
          <v-btn
            icon
            @click="close()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        Tem certeza que deseja excluir seu avatar?
        <v-card-actions>
          <v-spacer />
          <v-btn
            rounded
            outlined
            text
            color="primary"
            @click="deleteAvatar()"
          >
            Excluir
          </v-btn>
        </v-card-actions>
      </template>
      <template v-if="endAvatar">
        <v-card-title>
          {{ msgAvatar }}
          <v-spacer />
          <v-btn
            icon
            @click="close()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
      </template>
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ msgSnackbar }}

      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Load from '@/components/Loading.vue'

export default {
  components: {
    Load
  },

  props: {
    user: { type: Object, default: undefined },
    delete: { type: Boolean, default: true }
  },

  data: () => ({
    dataUser: {
      avatar: undefined
    },
    showInfo: true,
    endAvatar: false,
    msgAvatar: '',
    files: [],
    btnFile: true,
    errorMsg: '',
    loading: false,
    loadingMsg: '',
    delAvatar: false,
    snackbar: false,
    msgSnackbar: '',
    timeout: 2500
  }),
  created () {
    this.action()
  },
  methods: {
    action () {
      if (this.user.avatar) {
        this.showInfo = false
        this.delAvatar = true
      }
    },
    close () {
      this.$emit('close')
    },
    changeBtn () {
      if (this.files) {
        this.btnFile = false
      } else {
        this.btnFile = true
      }
    },
    async sendAvatar () {
      const formData = new FormData()
      formData.append('avatar', this.files)
      this.$emit('upload', formData)
    },
    async deleteAvatar () {
      this.$emit('delete')
    }
  }
}
</script>
