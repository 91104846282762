import Vue from 'vue'
import VueRouter from 'vue-router'
import api from '@/plugins/api.js'
import Home from '@/views/Home.vue'
import Roles from '@/views/intranetUsers/Roles.vue'
import Permissions from '@/views/intranetUsers/Permissions.vue'
import Users from '@/views/intranetUsers/Users.vue'
import User from '@/views/intranetUsers/User.vue'
import Painel from '@/views/Painel.vue'
import Institutions from '@/views/School.vue'
import Institution from '@/views/Schools/School_id.vue'
import Gradelevelgroup from '@/views/settings/Grade_level_group.vue'
import GradelevelgroupID from '@/views/settings/Grade_level_group_id.vue'
import GradelevelID from '@/views/settings/Grade_level_id.vue'
import QuestionBoards from '@/views/settings/Question_boards.vue'
import QuestionBoardID from '@/views/settings/Question_board_id.vue'
import Subjects from '@/views/settings/Subjects.vue'
import Subject from '@/views/settings/Subject_id.vue'
import Topic from '@/views/settings/Topic_id.vue'
import TagTypes from '@/views/settings/Tag_Type.vue'
import TagType from '@/views/settings/Tag_Type_id.vue'
import Tags from '@/views/settings/Tags.vue'
import Tag from '@/views/settings/Tag_id.vue'

import contents from './groups/contents'
import audeExams from './groups/audeExams'
import hierarchy from './groups/hierarchy'
import portalUsers from './groups/portalUsers'

Vue.use(VueRouter)

const routes = [
  ...contents,
  ...audeExams,
  ...hierarchy,
  ...portalUsers,

  {
    path: '/',
    name: 'Login',
    component: Home
  },
  {
    path: '/painel',
    name: 'painel',
    component: Painel
  },
  {
    path: '/cargos',
    name: 'roles',
    component: Roles
  },
  {
    path: '/permissoes/:id',
    name: 'permissions',
    component: Permissions
  },
  {
    path: '/usuarios',
    name: 'intranet-users',
    component: Users
  },
  {
    path: '/usuarios/:id',
    name: 'intranet-user.id',
    component: User
  },
  {
    path: '/escolas',
    name: 'institutions',
    component: Institutions
  },
  {
    path: '/escola/:id',
    name: 'institution.id',
    component: Institution
  },
  {
    path: '/segmentos',
    name: 'gradelevelgroup',
    component: Gradelevelgroup
  },
  {
    path: '/segmentos/:id',
    name: 'segmentos-id',
    component: GradelevelgroupID
  },
  {
    path: '/curso/:id',
    name: 'curso-id',
    component: GradelevelID
  },
  {
    path: '/materia/:id',
    name: 'materia-id',
    component: Subject
  },
  {
    path: '/materias',
    name: 'subjects',
    component: Subjects
  },
  {
    path: '/banca/:id',
    name: 'banca-id',
    component: QuestionBoardID
  },
  {
    path: '/bancas',
    name: 'question.boards',
    component: QuestionBoards
  },
  {
    path: '/topico/:id',
    name: 'topico-id',
    component: Topic
  },
  {
    path: '/tag-type',
    name: 'tag-type',
    component: TagTypes
  },
  {
    path: '/tag-type/:id',
    name: 'tag-type-id',
    component: TagType
  },
  {
    path: '/tags',
    name: 'tags',
    component: Tags
  },
  {
    path: '/tag/:id',
    name: 'tag-id',
    component: Tag
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && api.checkLogin() === false) next({ name: 'Login' })
  else next()
})

export default router
