import axios from 'axios'

// Institutions

export async function getSchool () {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/institutions')
  return response.data.data
}

export async function getSchoolId (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/institutions/' + id + '?with=maintainers')
  return response.data.data
}

export async function saveSchool (data) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/institutions', data)
  return response.data.data
}

export async function updateSchool (data, id) {
  await axios.put(import.meta.env.VITE_API_URL + '/v1/intranet/institutions/' + id, data)
}

export async function deleteSchool (id) {
  const response = await axios.delete(import.meta.env.VITE_API_URL + '/v1/intranet/institutions/' + id)
  return response.data.data
}

export async function newContract (id, data) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/institutions/' + id + '/contracts', data)
  return response.data.data
}

export async function getContracts (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/institutions/' + id + '/contracts?with=contractGradeLevelItems')
  return response.data.data
}
export async function getContract (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/contracts/' + id + '?with=contractGradeLevelItems')
  return response.data.data
}
export async function updateContract (id, data) {
  const response = await axios.put(import.meta.env.VITE_API_URL + '/v1/intranet/contracts/' + id, data)
  return response.data.data
}
export async function newContractItem (id, data) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/contracts/' + id + '/contract-grade-level-items', data)
  return response.data.data
}
export async function updateContractItem (id, data) {
  const response = await axios.put(import.meta.env.VITE_API_URL + '/v1/intranet/contract-grade-level-items/' + id, data)
  return response.data.data
}
export async function deleteContractItem (id) {
  const response = await axios.delete(import.meta.env.VITE_API_URL + '/v1/intranet/contract-grade-level-items/' + id)
  return response.data.data
}

export async function saveMaintainers (idInstitutuon, data) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/institutions/' + idInstitutuon + '/maintainers', data)
  return response.data.data
}

export async function resetPasswordMaintainer (id) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/maintainers/' + id + '/reset-password')
  return response.data.data
}

export default {
  getSchool,
  getSchoolId,
  saveSchool,
  updateSchool,
  deleteSchool,
  newContract,
  getContracts,
  getContract,
  updateContract,
  saveMaintainers,
  resetPasswordMaintainer,
  newContractItem,
  updateContractItem,
  deleteContractItem
}
