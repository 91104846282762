const TeachingSystems = () => import('@/views/settings/hierarchy/TeachingSystems.vue')
const GradeLevelGroups = () => import('@/views/settings/hierarchy/GradeLevelGroups.vue')
const GradeLevels = () => import('@/views/settings/hierarchy/GradeLevels.vue')
const Subjects = () => import('@/views/settings/hierarchy/Subjects.vue')
const Topics = () => import('@/views/settings/hierarchy/Topics.vue')
const Contents = () => import('@/views/settings/hierarchy/Contents.vue')

export default [
  {
    path: '/conteudos-v2/sistemas-de-ensino',
    name: 'teachingSystems',
    component: TeachingSystems
  },
  {
    path: '/conteudos-v2/sistemas-de-ensino/:teachingSystemID',
    name: 'teachingSystemId',
    component: GradeLevelGroups
  },
  {
    path: '/conteudos-v2/sistemas-de-ensino/:teachingSystemID/segmento/:gradeLevelGroupID',
    name: 'gradeLevelGroupId',
    component: GradeLevels
  },
  {
    path: '/conteudos-v2/sistemas-de-ensino/:teachingSystemID/segmento/:gradeLevelGroupID/ano/:gradeLevelID',
    name: 'gradeLevelId',
    component: Subjects
  },
  {
    path: '/conteudos-v2/sistemas-de-ensino/:teachingSystemID/segmento/:gradeLevelGroupID/ano/:gradeLevelID/materia/:subjectID',
    name: 'subjectId',
    component: Topics
  },
  {
    path: '/conteudos-v2/sistemas-de-ensino/:teachingSystemID/segmento/:gradeLevelGroupID/ano/:gradeLevelID/materia/:subjectID/volume/:volumeID/topico/:topicID',
    name: 'Contents',
    component: Contents
  }
]
