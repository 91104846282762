import axios from 'axios'

// Media contents

export async function getMediaContents (page, parameters) {
  let url = import.meta.env.VITE_API_URL + '/v1/intranet/media-contents?page=' + page
  if (parameters) {
    url = url + parameters
  }

  const response = await axios.get(url)
  return response.data
}

export async function saveMediaContent (data) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/media-contents', data)
  return response.data.data
}

export async function quickSaveMediaContent (data) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/media-contents/quick-create', data)
  return response.data.data
}

export async function updateMediaContent (id, data) {
  await axios.put(import.meta.env.VITE_API_URL + '/v1/intranet/media-contents/' + id, data)
}

export async function deleteMediaContent (id) {
  await axios.delete(import.meta.env.VITE_API_URL + '/v1/intranet/media-contents/' + id)
}

export async function getMediaContentID (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/media-contents/' + id + '?with=sources,tags,topics')
  return response.data.data
}

export async function saveSource (data, id) {
  await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/media-contents/' + id + '/sources', data)
}

export async function deleteSource (id) {
  await axios.delete(import.meta.env.VITE_API_URL + '/v1/intranet/sources/' + id)
}

export async function sendContentThumbnail (formData, id) {
  await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/media-contents/' + id + '/thumbnail', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
}
export async function deleteContentThumbnail (id) {
  await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/media-contents/' + id + '/delete-thumbnail')
}

export default {
  getMediaContents,
  saveMediaContent,
  quickSaveMediaContent,
  updateMediaContent,
  deleteMediaContent,
  getMediaContentID,
  saveSource,
  deleteSource,
  sendContentThumbnail,
  deleteContentThumbnail
}
