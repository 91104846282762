import Vue from 'vue'
import api from '@/api/realapi.js'
// import api from '@/api/fakeapi.js'

Object.defineProperties(Vue.prototype, {
  $api: {
    get () {
      return api
    }
  }
})

export default api
