const PortalUsers = () => import('@/views/portal/Users.vue')
const PortalUserID = () => import('@/views/portal/User.vue')

export default [
  {
    path: '/portal/usuarios',
    name: 'portal-users',
    component: PortalUsers
  },
  {
    path: '/portal/usuarios/:id',
    name: 'portal-user',
    component: PortalUserID
  }
]
