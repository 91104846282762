import axios from 'axios'

// Material Text Book

export function NewMaterialTextBook (data) {
  return axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/subject-textbooks', data)
}

export async function GetMaterialTextBooks (page, parameters) {
  let url = import.meta.env.VITE_API_URL + '/v1/intranet/subject-textbooks?page=' + page

  if (parameters) {
    url = url + parameters
  }

  const response = await axios.get(url)
  return response.data
}
export async function GetMaterialTextBook (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/subject-textbooks/' + id)
  return response.data.data
}

export async function uploadMaterial (formData) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/subject-textbooks/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
  return response.data.data
}

export async function uploadCoverMaterial (formData) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/subject-textbooks/upload-cover', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
  return response.data.data
}

export function updateMaterialTextBook (id, data) {
  return axios.put(import.meta.env.VITE_API_URL + '/v1/intranet/subject-textbooks/' + id, data)
}

export function deleteMaterialTextBook (id) {
  return axios.delete(import.meta.env.VITE_API_URL + '/v1/intranet/subject-textbooks/' + id)
}

export default {
  NewMaterialTextBook,
  GetMaterialTextBooks,
  GetMaterialTextBook,
  uploadMaterial,
  uploadCoverMaterial,
  updateMaterialTextBook,
  deleteMaterialTextBook
}
