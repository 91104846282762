import axios from 'axios'

// example
export function NewArticle (data) {
  return axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/academic-article-textbooks', data)
}

export async function ListArticles (page) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/academic-article-textbooks?page=' + page)
  return response.data
}
export async function getArticle (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/academic-article-textbooks/' + id)
  return response.data.data
}

export async function uploadArticle (formData) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/academic-article-textbooks/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
  return response.data.data
}

export async function uploadCoverArticle (formData) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/academic-article-textbooks/upload-cover', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
  return response.data.data
}

export function updateArticle (id, data) {
  return axios.put(import.meta.env.VITE_API_URL + '/v1/intranet/academic-article-textbooks/' + id, data)
}

export function deleteArticle (id) {
  return axios.delete(import.meta.env.VITE_API_URL + '/v1/intranet/academic-article-textbooks/' + id)
}

export default {
  NewArticle,
  ListArticles,
  getArticle,
  uploadArticle,
  uploadCoverArticle,
  updateArticle,
  deleteArticle
}
