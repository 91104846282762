<template>
  <div>
    <v-list
      nav
      dense
    >
      <template
        v-for="item in menuItems"
      >
        <v-list-item
          v-if="item.permission && !item.children"
          :key="item.name"
          color="primary"
          :to="item.to"
          link
        >
          <v-list-item-icon v-if="item.icon">
            <v-icon v-text="item.icon" />
          </v-list-item-icon>
          <v-list-item-title v-text="item.title" />
        </v-list-item>

        <v-list-group
          v-else-if="item.children && item.children.some(c => c.permission)"
          :key="item.name"
          v-model="item.active"
          :prepend-icon="item.icon"
          no-action
        >
          <template #activator>
            <v-list-item-title v-text="item.title" />
          </template>

          <template v-for="child in item.children">
            <v-list-item
              v-if="child.permission"
              :key="child.title"
              :to="child.to"
              link
            >
              <v-list-item-title v-text="child.title" />
            </v-list-item>
          </template>
        </v-list-group>
      </template>
    </v-list>
  </div>
</template>

<script>
import utils from '@/api/utils.js'

export default {
  data: () => ({
    menuItems: []
  }),

  async created () {
    this.addMenuItems()
  },

  methods: {
    addMenuItems () {
      this.menuItems = [
        {
          title: 'Home',
          icon: 'mdi-home',
          to: { name: 'painel' },
          permission: true
        }, {
          title: 'Usuários da Intranet',
          icon: 'mdi-account-multiple-outline',
          to: { name: 'intranet-users' },
          permission: utils.checkPermissions('intranet-users.view-any')
        }, {
          title: 'Cargos',
          icon: 'mdi-badge-account-horizontal-outline',
          to: { name: 'roles' },
          permission: utils.checkPermissions('roles.view-any')
        }, {
          title: 'Usuários do Portal',
          icon: 'mdi-account-multiple-outline',
          to: { name: 'portal-users' },
          permission: utils.checkPermissions('users.view-any')
        }, {
          title: 'Instituições',
          icon: 'mdi-home-city-outline',
          to: { name: 'institutions' },
          permission: utils.checkPermissions('institutions.view-any')
        }, {
          title: 'Conteúdos',
          icon: 'mdi-animation-play-outline',
          active: false,
          children: [
            {
              title: 'Mídia',
              to: { name: 'contents.media' },
              permission: utils.checkAnyOrOwn('media-contents.view-any', 'media-contents.view-own')
            }, {
              title: 'Questões',
              to: { name: 'contents.questions' },
              permission: utils.checkAnyOrOwn('questions.view-any', 'questions.view-own')
            }, {
              title: 'Materiais Didáticos',
              to: { name: 'materials' },
              permission: utils.checkAnyOrOwn('subject-textbooks.view-any', 'subject-textbooks.view-own')
            }, {
              title: 'Artigos Acadêmicos',
              to: { name: 'articles' },
              permission: utils.checkAnyOrOwn('academic-articles.view-any', 'academic-articles.view-own')
            }, {
              title: 'Avaliação AUDE',
              to: { name: 'aude-exams' },
              permission: utils.checkAnyOrOwn('questions.view-any', 'questions.view-own')
            }
          ]
        }, {
          title: 'Portal',
          icon: 'mdi-format-list-checkbox',
          active: false,
          children: [
            {
              title: 'Segmentos',
              to: { name: 'gradelevelgroup' },
              permission: utils.checkPermissions('grade-level-groups.view-any')
            }, {
              title: 'Matérias',
              to: { name: 'subjects' },
              permission: utils.checkPermissions('subjects.view-any')
            }, {
              title: 'Tipos de tags',
              to: { name: 'tag-type' },
              permission: utils.checkPermissions('tag-types.view-any')
            }, {
              title: 'Tags',
              to: { name: 'tags' },
              permission: utils.checkPermissions('tags.view-any')
            }, {
              title: 'Bancas',
              to: { name: 'question.boards' },
              permission: utils.checkAnyOrOwn('question-boards.view-any', 'question-boards.view-own')
            }, {
              title: 'Conteúdos v2',
              to: { name: 'teachingSystems' },
              permission: true
            }
          ]
        }
      ]
    }
  }
}
</script>
