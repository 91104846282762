export default {
  /**
   * @deprecated As this is being used with objects, it's recommended to use
   * objectMap instead
   */
  setErrorsData (data) {
    const newItems = []
    for (const variable in data) {
      newItems[variable] = data[variable][0]
    }
    return newItems
  },

  firstOfEveryProperty (obj) {
    return this.objectMap(obj, obj => obj[0])
  },

  objectMap (obj, callback) {
    return Object.fromEntries(
      Object.entries(obj).map(
        ([k, v], i) => [k, callback(v, k, i)]
      )
    )
  },

  setUser (data) {
    localStorage.setItem('currentUser', JSON.stringify(data))
  },

  setPermissions (data) {
    localStorage.setItem('permissions', JSON.stringify(data))
  },

  getUser () {
    return JSON.parse(localStorage.getItem('currentUser'))
  },

  setGradeLevels (data) {
    localStorage.setItem('GradeLevels', JSON.stringify(data))
  },

  getGradeLevels () {
    return JSON.parse(localStorage.getItem('GradeLevels'))
  },

  getPermissions () {
    return JSON.parse(localStorage.getItem('permissions'))
  },

  checkPermissions (data) {
    const permissions = JSON.parse(localStorage.getItem('permissions')).map(item => item.name)
    return permissions.includes(data)
  },

  checkAnyOrOwn (any, own, ownerUserId, actorUserId) {
    if (this.checkPermissions(any)) {
      return true
    }

    if (this.checkPermissions(own) && ownerUserId && actorUserId) {
      return ownerUserId === actorUserId
    }

    return false
  },

  /**
   * @deprecated The use of this function is discouraged because it only clone
   * shallow properties, and always return an object. Recommended alternative
   * is lodash.cloneDeep https://lodash.com/docs/4.17.15#cloneDeep
   */
  currentData (data) {
    const newItems = {}
    for (const variable in data) {
      newItems[variable] = data[variable]
    }
    return newItems
  },

  /**
   * @deprecated The use of this function is discouraged because it creates
   * an array with non-numeric keys. Recommended alternative is lodash.cloneDeep
   * https://lodash.com/docs/4.17.15#cloneDeep
   */
  currentDataArray (data) {
    const newItems = []
    for (const variable in data) {
      newItems[variable] = data[variable]
    }
    return newItems
  },

  newData (updateData, current) {
    const newItems = {}
    for (const variable in updateData) {
      if (updateData[variable] !== current[variable]) {
        newItems[variable] = updateData[variable]
      }
    }
    return newItems
  },

  size (value) {
    if (!value) return 0
    return value.size || value.length || Object.keys(value).length
  },
  newTopicList (data, subjects) {
    const newItems = {}
    for (const variable in data) {
      newItems[variable] = data[variable]
    }
    newItems.subjectName = this.subjectName(subjects, data.subject_id)
    return newItems
  },
  subjectName (subjects, id) {
    try {
      const name = subjects.find(subject => subject.id === id).name
      return name
    } catch {
      return 'matéria não encontrada'
    }
  },

  statusType () {
    const types = [
      { statusName: 'Inativo', value: 0 },
      { statusName: 'Ativo', value: 1 }
    ]
    return types
  },

  contentType () {
    const types = [
      { typeName: 'Video Aula', value: 1 },
      { typeName: 'Bizu', value: 5 },
      { typeName: 'Questão Comentada', value: 2 },
      { typeName: 'AudioBook', value: 4 },
      { typeName: 'Podcast', value: 3 }
    ]
    return types
  },
  roleType () {
    const types = [
      { text: 'Aluno', value: 0 },
      { text: 'Professor', value: 1 },
      { text: 'Mantenedor', value: 2 },
      { text: 'Responsável', value: 3 },
      { text: 'Assinante', value: 4 },
      { text: 'Coordenador', value: 5 }
    ]
    return types
  },
  /**
   * Retorna a diferença entre dois objetos
   *
   * Essa função recebe duas versões do mesmo objeto retornando apenas a diferença do novo para o atual, desconsiderando strings, arrays ou objetos vazios
   */
  diffObjects (newObject, currentObject, path = []) {
    const result = {}
    const keys = new Set([...Object.keys(newObject), ...Object.keys(currentObject)])
    keys.forEach(key => {
      const newValue = newObject[key]
      const currentValue = currentObject[key]
      const currentPath = path.concat(key)
      if (newValue === null && currentValue !== null) {
        result[key] = newValue
        return
      }
      if (currentValue === null && newValue !== null) {
        if (Array.isArray(newValue) && newValue.length === 0) {
          return
        }
        if (typeof newValue === 'string' && newValue === '') {
          return
        }
        if (typeof newValue === 'object' && Object.keys(newValue).length === 0) {
          return
        }
        result[key] = newValue
        return
      }
      if (newValue === null && currentValue === null) {
        return
      }
      if (Array.isArray(newValue) && Array.isArray(currentValue)) {
        if (newValue.length !== currentValue.length || !newValue.every((val, index) => val === currentValue[index])) {
          result[key] = newValue
        }
        return
      }
      if (typeof newValue === 'object' && typeof currentValue === 'object') {
        const nestedDiff = this.diffObjects(newValue, currentValue, currentPath)
        if (Object.keys(nestedDiff).length > 0) {
          result[key] = nestedDiff
        }
      } else if (newValue !== currentValue) {
        result[key] = newValue
      }
    })
    return result
  }
}
