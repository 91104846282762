<template>
  <div>
    <template v-if="loading">
      <Load :loading-msg="loadingMsg" />
    </template>
    <template v-else>
      <v-card-title>
        Cadastro do Usuário
      </v-card-title>
      <v-card-text class="d-flex justify-center mt-4 mb-4">
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-avatar
              color="indigo"
              size="128"
            >
              <img
                v-if="user.avatar"
                :src="user.avatar"
                :alt="user.name"
              >
              <v-icon
                v-else
                dark
              >
                mdi-account-circle
              </v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-row>
          <v-col class="text-center">
            <v-speed-dial
              v-model="fab"
              direction="bottom"
              :open-on-hover="false"
              transition="slide-y-reverse-transition"
            >
              <template #activator>
                <v-btn
                  v-model="fab"
                  color="blue darken-2"
                  dark
                  fab
                >
                  <v-avatar
                    v-if="user.avatar"
                    size="128"
                  >
                    <img
                      :src="user.avatar"
                      :alt="user.name"
                    >
                  </v-avatar>
                  <v-icon
                    v-else
                  >
                    mdi-camera
                  </v-icon>
                </v-btn>
              </template>
              <v-btn
                v-if="!user.avatar"
                fab
                dark
                small
                color="indigo"
                @click="avatar()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn
                v-if="user.avatar"
                fab
                dark
                small
                color="red"
                @click="avatar()"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-speed-dial>
          </v-col>
        </v-row>
      </v-card-text>
      <v-form
        ref="form"
      >
        <v-text-field
          v-model="user.name"
          prepend-icon="mdi-account"
          :error-messages="formError.name"
          :rules="rulesName"
          label="Nome"
          @input="formError.name = ''"
        />
        <v-text-field
          v-model="user.email"
          prepend-icon="mdi-email"
          label="E-mail de acesso"
          :error-messages="formError.email"
          :rules="rulesEmail"
          @input="formError.email = ''"
        />
        <v-text-field
          v-model="user.password"
          prepend-icon="mdi-lock"
          type="password"
          label="Senha"
          :error-messages="formError.password"
          :rules="rulesPassword"
          @input="formError.password = ''"
        />
      </v-form>
      <div class="d-flex justify-space-between">
        <v-btn
          color="primary"
          text
          @click="close()"
        >
          fechar
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="save()"
        >
          Salvar
        </v-btn>
      </div>
    </template>
  </div>
</template>

<script>
import Load from '@/components/Loading.vue'
import utils from '@/api/utils.js'

export default {
  components: {
    Load
  },

  data: () => ({
    rulesName: [
      value => !!value || 'Nome é obrigatório.',
      value => (value && value.length >= 3) || 'Min 3 caracteres'
    ],
    rulesEmail: [
      v => !!v || 'E-mail é obrigatório',
      v => /.+@.+\..+/.test(v) || 'E-mail inválido'
    ],
    rulesPassword: [
      value => !value || (utils.size(value) >= 8) || 'Min 8 caracteres'
    ],
    formError: {},
    loading: false,
    loadingMsg: '',
    user: {},
    fab: false,
    currentUser: {}
  }),
  created () {
    this.fetchAll()
  },
  methods: {
    fetchAll () {
      this.loading = true
      this.getMe()
      this.loading = false
    },
    async getMe () {
      this.user = await utils.getUser()
      this.currentUser = utils.currentData(this.user)
    },
    validate () {
      return this.$refs.form.validate()
    },
    close () {
      this.$emit('close')
    },

    async save () {
      const validate = await this.validate()
      if (validate === false) {
        return false
      }
      this.loading = true
      this.loadingMsg = 'Salvando...'
      const data = utils.newData(this.user, this.currentUser)
      this.$emit('update', data)
      this.loading = false
    },
    avatar () {
      this.$emit('avatar')
    },
    async deleteAvatar () {
      this.loading = true
      this.delAvatar = false
      this.loadingMsg = 'Apagando imagem...'
      try {
        await this.$api.deleteAvatar(this.idUser)
        this.$emit('close')
        this.msgAvatar = 'Avatar apagado'
      } catch {
        this.snackbar = true
        this.msgSnackbar = 'ocorreu um erro ao tentar apagar'
        this.msgAvatar = 'ocorreu um erro ao tentar apagar'
      }
      this.loading = false
      this.endAvatar = true
    }
  }
}
</script>
