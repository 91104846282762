<template>
  <div>
    <v-card-title>
      Cadastrar {{ title }}
    </v-card-title>
    <v-card-text>
      <v-form
        ref="form"
      >
        <v-text-field
          v-model="name"
          :rules="rulesName"
          :error-messages="formError.name"
          label="Nome do tópico"
          @input="formError.name = ''"
        />

        <v-select
          v-model="teachingSystem"
          :items="teachingSystems"
          :rules="teachingSystemsRules"
          item-text="name"
          item-value="id"
          label="Sistema de Ensino"
          @change="getVolumes"
        />

        <v-select
          v-model="subject"
          :items="subjects"
          :rules="rulesSubject"
          item-text="name"
          item-value="id"
          label="Matéria"
        />

        <v-select
          v-model="volume"
          :items="volumes"
          item-text="name"
          item-value="id"
          label="Volume"
        />
      </v-form>
    </v-card-text>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <v-btn
          color="primary"
          text
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="save()"
        >
          Salvar {{ title }}
        </v-btn>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import { listTypes as listVolumeTypes } from '@/enums/volumeTypes'
export default {
  components: {
  },
  props: {
    title: { type: String, default: undefined },
    subjects: { type: Array, default: () => [] },
    teachingSystems: { type: Array, default: () => [] }
  },

  data: () => ({
    rulesName: [
      value => !!value || 'Peencha este campo.'
    ],
    rulesSubject: [
      value => !!value || 'Peencha este campo.'
    ],
    teachingSystemsRules: [
      value => !!value || 'Peencha este campo.'
    ],
    formError: {},
    name: '',
    subject: '',
    teachingSystem: null,
    volumes: [],
    volume: null,
    id: null
  }),

  created () {
    this.fetchAll()
    // this.id = this.$route.params.id
    // // console.log(this.id)
  },
  methods: {
    listVolumeTypes,
    fetchAll () {
      // this.volumes = listVolumeTypes()
    },
    validate () {
      return this.$refs.form.validate()
    },
    close () {
      this.$emit('close')
    },

    async getVolumes () {
      try {
        this.volumes = await this.$api.getVolumes(this.$route.params.id, this.teachingSystem)
      } catch (e) {
        // console.log(e)
      }
    },

    async save () {
      const validate = await this.validate()
      if (validate === false) {
        return false
      }
      const data = {
        name: this.name,
        subject_id: this.subject,
        teaching_system_id: this.teachingSystem,
        volume_id: this.volume
      }
      this.$emit('save', data)
    }
  }
}
</script>
