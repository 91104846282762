<template>
  <ItemPage
    title="Segmento - Curso/Ano - Tópico"
    size="md"
    :actions="actions"
  >
    <v-form
      ref="form"
    >
      <v-container>
        <v-row>
          <v-col
            cols="3"
            class="mr-auto"
          >
            <v-text-field
              v-model="topic.name"
              :disabled="topicsUpdate"
              label="Nome"
              @input="checkUpdate"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="topic.teaching_system_id"
              :disabled="topicsUpdate"
              :items="teachingSystems"
              :rules="teachingSystemRules"
              item-text="name"
              item-value="id"
              label="Sistema de Ensino"
              @change="checkUpdate"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="topic.volume_id"
              :disabled="topicsUpdate"
              :items="volumes"
              item-text="name"
              item-value="id"
              label="Volumes"
              @change="checkUpdate"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="topic.subject_id"
              :disabled="topicsUpdate"
              :items="subjects"
              :rules="rulesSubject"
              item-text="name"
              item-value="id"
              label="Matéria"
              @change="checkUpdate"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-dialog
      v-model="dialogConfirm"
      width="500"
      persistent
    >
      <v-card>
        <template v-if="request">
          <Load :loadingmsg="loadingMsg" />
        </template>
        <template v-else>
          <DialogConfirm
            :key="renderComponent"
            title="o Tópico"
            :name="topic.name"
            @close="dialogConfirm = false"
            @confirm="deleteTopic($event)"
          />
        </template>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ msgSnackbar }}

      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import DialogConfirm from '@/components/Dialog_confirm.vue'
import utils from '@/api/utils.js'
import { listTypes as listVolumeTypes } from '@/enums/volumeTypes'
import ItemPage from '@/components/pages/ItemPage.vue'

export default {
  components: {
    DialogConfirm,
    ItemPage,
    Load
  },
  data () {
    return {
      topicsUpdate: false,
      topicsDelete: false,
      dialogConfirm: false,
      btnDisable: true,
      renderComponent: 0,
      rulesName: [
        value => !!value || 'Preencha este campo.'
      ],
      rulesSubject: [
        value => !!value || 'Preencha este campo.'
      ],
      teachingSystemRules: [
        value => !!value || 'Preencha este campo.'
      ],
      dialogAddTopic: false,
      loading: false,
      subjects: [],
      loadingSave: false,
      update: false,
      request: false,
      snackbar: false,
      currentTopic: {},
      topic: {},
      msgSnackbar: '',
      timeout: 3000,
      volumes: [],
      formError: [],
      teachingSystems: [],
      teachingSystem: null
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },

    actions () {
      return [
        {
          label: 'Atualizar',
          action: this.updateTopic,
          icon: 'mdi-floppy',
          disabled: this.btnDisable
        },
        {
          label: 'Deletar',
          action: () => { this.dialogConfirm = true },
          icon: 'mdi-delete',
          disabled: this.topicsDelete
        }
      ]
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    listVolumeTypes,
    async fetchAll () {
      await this.getSubjects()
      await this.getTopic()
      await this.getVolumes()
      await this.fetchTeachingSystems()
      this.topicsUpdate = !utils.checkPermissions('topics.update-any')
      this.topicsDelete = !utils.checkPermissions('topics.delete')
    },
    async getSubjects () {
      try {
        this.subjects = await this.$api.getSubjects()
      } catch (e) {
        // console.log(e)
      }
    },

    async getVolumes () {
      try {
        this.volumes = await this.$api.getVolumes(this.topic.grade_level_id, this.topic.teaching_system_id)
      } catch (e) {
        // console.log(e)
      }
    },

    async fetchTeachingSystems () {
      this.loading = true
      this.loadingMsg = 'Carregando Sistemas de Ensino...'
      const response = await this.$api.getTeachingSystems()
      this.teachingSystems = response
      this.loading = false
    },

    async getTopic () {
      this.loading = true
      this.loadingMsg = 'Carregando lista de tópicos...'
      try {
        const response = await this.$api.getTopic(this.id)
        this.topic = response
        this.currentTopic = utils.currentData(response)
      } catch {
        // console.log('Erro ao obter levels')
      }
      this.loading = false
    },
    sizes (data) {
      return utils.size(data)
    },
    checkUpdate () {
      const data = utils.newData(this.topic, this.currentTopic)
      if (utils.size(data) > 0) {
        this.btnDisable = false
      } else {
        this.btnDisable = true
      }
      return data
    },
    checkDelete () {
      this.renderComponent += 1
      this.dialogConfirm = true
    },
    async deleteTopic () {
      this.request = true
      this.loadingMsg = 'Excluindo...'
      try {
        await this.$api.disableTopic(this.id)
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Excluido com sucesso'
        this.$router.back()
      } catch (e) {
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível Excluir, tente novamente mais tarde'
        // console.log(e)
      }
      this.request = false
    },
    async updateTopic () {
      const validate = await this.validate()
      if (validate === false) {
        return false
      }
      this.request = true
      const data = this.checkUpdate()
      try {
        await this.$api.updateTopic(this.id, data)
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Atualizado com sucesso'
        this.fetchAll()
      } catch (e) {
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível atualizar, tente novamente mais tarde'
        // console.log(e)
      }
      this.request = false
    },
    validate () {
      return this.$refs.form.validate()
    }
  }
}
</script>
