import axios from 'axios'

// Portal users

export async function searchPortalUsers (search) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/users?search=' + search)
  return response.data.data
}

export async function getPortalUser (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/user/' + id + '?with=roles.institution')
  return response.data.data
}

export async function updatePortalUser (id, data) {
  await axios.put(import.meta.env.VITE_API_URL + '/v1/intranet/user/' + id, data)
}

export async function resetPortalUserPassword (id) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/user/' + id + '/reset-password')
  return response.data.data
}

export default {
  searchPortalUsers,
  getPortalUser,
  updatePortalUser,
  resetPortalUserPassword
}
