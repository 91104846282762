export const types = {
  b1: 1,
  b2: 2,
  b3: 3,
  b4: 4,
  s1: 5,
  s2: 6,
  m1: 7,
  m2: 8
}
export const displayNames = {
  [types.b1]: '1º bimestre',
  [types.b2]: '2º bimestre',
  [types.b3]: '3º bimestre',
  [types.b4]: '4º bimestre',
  [types.s1]: '1º Semestre',
  [types.s2]: '2º Semestre',
  [types.m1]: 'Módulo I',
  [types.m2]: 'Módulo II'
}
export function listTypes () {
  const data = [
    { name: '1º bimestre', id: 1 },
    { name: '2º bimestre', id: 2 },
    { name: '3º bimestre', id: 3 },
    { name: '4º bimestre', id: 4 },
    { name: '1º Semestre', id: 5 },
    { name: '2º Semestre', id: 6 },
    { name: 'Módulo I', id: 7 },
    { name: 'Módulo II', id: 8 }
  ]
  return data
}
export function getTextForDisplay (type) {
  const name = displayNames[type]

  if (name === undefined) {
    return ''
  }

  return name
}

export default {
  // types,
  listTypes,
  // displayNames,
  getTextForDisplay
}
