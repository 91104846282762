import axios from 'axios'

// example
export function NewAudeExam (data) {
  return axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/aude-exams', data)
}

export async function ListAudeExams () {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/aude-exams')
  return response.data.data
}
// export async function ListAudeExams (page) {
//   const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/aude/exams?page=' + page)
//   return response.data
// }
export async function getAudeExam (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/aude-exams/' + id)
  return response.data.data
}

export async function updateAudeExam (id, data) {
  const response = await axios.put(import.meta.env.VITE_API_URL + '/v1/intranet/aude-exams/' + id, data)
  return response.data.data
}
export async function publishAudeExam (id) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/aude-exams/' + id + '/publish')
  return response.data.data
}
export async function unpublishAudeExam (id) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/aude-exams/' + id + '/unpublish')
  return response.data.data
}

export function deleteAudeExam (id) {
  return axios.delete(import.meta.env.VITE_API_URL + '/v1/intranet/aude-exams/' + id)
}

// QUESTION GROUPS
export function newQuestionsGroup (idExam, data) {
  return axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/aude-exams/' + idExam + '/aude-exam-groups', data)
}
export async function ListQuestionsGroups (idExam) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/aude-exams/' + idExam + '/aude-exam-groups')
  return response.data.data
}
export async function getQuestionsGroup (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/aude-exam-groups/' + id)
  return response.data.data
}

export async function updateQuestionsGroup (id, data) {
  const response = await axios.put(import.meta.env.VITE_API_URL + '/v1/intranet/aude-exam-groups/' + id, data)
  return response.data.data
}
export function deleteQuestionsGroup (id) {
  return axios.delete(import.meta.env.VITE_API_URL + '/v1/intranet/aude-exam-groups/' + id)
}

// QUESTIONS
export function newAudeExamQuestions (idGroup, data) {
  return axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/aude-exam-groups/' + idGroup + '/aude-exam-questions', data)
}
export async function ListAudeExamQuestions (idExam) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/aude-exam-groups/' + idExam + '/questions')
  return response.data.data
}
export async function getAudeExamQuestion (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/aude-exam-questions/' + id)
  return response.data.data
}
export async function updateAudeExamQuestion (id, data) {
  const response = await axios.put(import.meta.env.VITE_API_URL + '/v1/intranet/aude-exam-questions/' + id, data)
  return response.data.data
}
export function deleteAudeExamQuestion (id) {
  return axios.delete(import.meta.env.VITE_API_URL + '/v1/intranet/aude-exam-questions/' + id)
}
export async function uploadAudeQuestionImage (id, formData, sizeTemplate) {
  const res = await axios.post(`${import.meta.env.VITE_API_URL}/v1/intranet/aude-exam-questions/${id}/question-images?size_template=${sizeTemplate}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return res.data.data
}

export default {
  NewAudeExam,
  ListAudeExams,
  getAudeExam,
  updateAudeExam,
  publishAudeExam,
  unpublishAudeExam,
  deleteAudeExam,
  //
  newQuestionsGroup,
  ListQuestionsGroups,
  getQuestionsGroup,
  updateQuestionsGroup,
  deleteQuestionsGroup,
  //
  newAudeExamQuestions,
  ListAudeExamQuestions,
  getAudeExamQuestion,
  updateAudeExamQuestion,
  uploadAudeQuestionImage,
  deleteAudeExamQuestion
}
