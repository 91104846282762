import axios from 'axios'

// Tags

export async function getTags () {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/tags')
  return response.data.data
}

export async function newTag (data) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/tags', data)
  return response.data.data
}

export async function getTag (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/tags/' + id)
  return response.data.data
}

export async function updateTag (id, data) {
  const response = await axios.put(import.meta.env.VITE_API_URL + '/v1/intranet/tags/' + id, data)
  return response.data.data
}

export async function deleteTag (id) {
  const response = await axios.delete(import.meta.env.VITE_API_URL + '/v1/intranet/tags/' + id)
  return response.data.data
}

export default {
  getTags,
  newTag,
  getTag,
  updateTag,
  deleteTag
}
