<template>
  <div>
    <div class="row d-flex justify-end">
      <div class="col-auto">
        <v-btn
          @click="openModal"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          novo contrato
        </v-btn>
      </div>
    </div>
    <v-dialog
      id="modal-NewContract"
      v-model="modalNewContract"
      persistent
      width="400"
    >
      <v-card>
        <v-card-title>
          Novo Contrato
        </v-card-title>
        <v-card-text>
          <v-menu
            ref="menu"
            v-model="menuSigned_at"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="signed_at"
                label="Data de assinatura"
                prepend-icon="mdi-calendar"
                :state="stateErrors.signed_at"
                :error-messages="errors.signed_at"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              ref="picker"
              v-model="signed_at"
              min="1990-01-01"
              @change="menuSigned_at = false"
            />
          </v-menu>
          <v-menu
            ref="menu"
            v-model="menuStarts_at"
            :close-on-content-click="false"
            transition="scale-transition"
            :state="true"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="starts_at"
                label="Data de inicio"
                prepend-icon="mdi-calendar"
                :state="true"
                :error-messages="errors.starts_at"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              ref="picker"
              v-model="starts_at"
              :state="true"
              :error-messages="errors.starts_at"
              min="1990-01-01"
              @change="menuStarts_at = false"
            />
          </v-menu>
          <v-menu
            ref="menu"
            v-model="menuExpires_at"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :disabled="starts_at === '' ? true : false"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="expires_at"
                :label="starts_at === '' ? 'Selecione a data inicial' :'Data final'"
                prepend-icon="mdi-calendar"
                readonly
                :disabled="starts_at === '' ? true : false"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              ref="picker"
              v-model="expires_at"
              :min="starts_at"
              @change="menuExpires_at = false"
            />
          </v-menu>
          <v-menu
            ref="menu"
            v-model="menuRenews_at"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :disabled="starts_at === '' ? true : expires_at === '' ? true : false"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="renews_at"
                :label="starts_at === '' ? 'Selecione a data inicial' : expires_at === '' ? 'Selecione a data final' : 'Data de renovação'"
                prepend-icon="mdi-calendar"
                readonly
                :disabled="starts_at === '' ? true : expires_at === '' ? true : false"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              ref="picker"
              v-model="renews_at"
              @change="menuRenews_at = false"
            />
          </v-menu>
          <div class="row d-flex justify-space-between">
            <v-btn
              text
              @click="modalNewContract = false"
            >
              Voltar
            </v-btn>
            <v-btn
              color="primary"
              @click="newContract"
            >
              Salvar contrato
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import utils from '@/api/utils.js'

export default {
  components: {
  },
  props: {
    id: { type: Number, required: true }
  },

  data: () => ({
    formError: {},
    dialog: false,
    modalNewContract: false,
    menuSigned_at: false,
    menuStarts_at: false,
    menuExpires_at: false,
    menuRenews_at: false,
    signed_at: '',
    starts_at: '',
    expires_at: '',
    renews_at: '',
    renderList: 0,
    errors: [],
    stateErrors: []
  }),
  watch: {
    modalNewContract () {
      if (this.modalNewContract === false) {
        this.starts_at = ''
        this.expires_at = ''
        this.renews_at = ''
      }
    }
  },
  async created () {
    await this.fetchAll()
  },
  methods: {
    async fetchAll () {
    },
    getErrors (data) {
      if (data.data.errors) {
        this.errors = utils.setErrorsData(data.data.errors)
        this.stateErrors = utils.setStateErrors(data.data.errors)
      } else {
        throw (data)
      }
    },
    validates () {
      return this.$refs.form.validate()
    },
    openModal () {
      this.modalNewContract = true
      this.renderList += 1
    },
    async newContract () {
      const data = {
        signed_at: this.signed_at,
        starts_at: this.starts_at,
        expires_at: this.expires_at,
        renews_at: this.renews_at
      }
      try {
        const response = await this.$api.newContract(this.id, data)
        this.$emit('saveNewContract', response)
        this.modalNewContract = false
        // console.log(response)
      } catch (e) {
        // console.log(e.response)
        this.getErrors(e.response)
      }
    }
  }
}
</script>
