import axios from 'axios'
import store from '@/store/index.js'
import router from '@/router/index.js'

axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true
axios.defaults.xsrfCookieName = 'INTRANET-XSRF-TOKEN'
axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 419) {
    const data = {
      status: true,
      msg: 'Sua sessão expirou, faça login novamente'
    }
    store.commit('setError', data)
    store.commit('setUser', undefined)
    localStorage.clear()
    // console.log('erro 419')
    router.push({ name: 'Login' })
  }
  if (error.response.status === 401) {
    const data = {
      status: true,
      msg: 'Sua sessão expirou, faça login novamente'
    }
    store.commit('setError', data)
    store.commit('setUser', undefined)
    localStorage.clear()
    // console.log('erro 401')
    router.push({ name: 'Login' })
  }
  if (error.response.status === 403) {
    const data = {
      status: true,
      msg: 'Você não tem permissão para executar esta ação'
    }
    store.commit('setError', data)
    // console.log('erro 403 - Você não tem permissão para executar esta ação')
    // router.go(-1)
  }
  if (error.response.status === 404) {
    // console.log('erro 404')
  }
  return Promise.reject(error)
})

export default axios
