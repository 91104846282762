<template>
  <ItemPage
    title="Matéria"
    size="md"
    :actions="actions"
  >
    <v-form
      ref="form"
    >
      <v-container>
        <v-row>
          <v-col
            cols="6"
            class="mr-auto"
          >
            <v-text-field
              v-model="subject.name"
              :rules="rulesName"
              :disabled="subjectsUpdate"
              label="Nome"
              @input="checkUpdate"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="subject.short_name"
              :disabled="subjectsUpdate"
              :rules="[() => (sizes(subject.name) < 20 || sizes(subject.short_name) > 1) || 'Nome maior que 20 caracteres, inclua sigla(min. 2 caracteres)', () => (subject.short_name === null || subject.short_name === '' || sizes(subject.short_name) <21 && sizes(subject.short_name) > 1 ) || 'inclua sigla(min. 2, max 20 caracteres)']"
              :error-messages="formError.short_name"
              :counter="20"
              maxlength="20"
              label="Abreviatura"
              @input="checkUpdate"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-dialog
      v-model="dialogConfirm"
      width="500"
      persistent
    >
      <v-card>
        <template v-if="request">
          <Load :loadingmsg="loadingMsg" />
        </template>
        <template v-else>
          <DialogConfirm
            :key="renderComponent"
            title="a Máteria"
            :name="subject.name"
            @close="dialogConfirm = false"
            @confirm="deleteSubject($event)"
          />
        </template>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ msgSnackbar }}

      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import DialogConfirm from '@/components/Dialog_confirm.vue'
import utils from '@/api/utils.js'
import ItemPage from '@/components/pages/ItemPage.vue'

export default {
  components: {
    DialogConfirm,
    Load,
    ItemPage
  },
  data () {
    return {
      dialogConfirm: false,
      btnDisable: true,
      renderComponent: 0,
      rulesName: [
        value => !!value || 'Preenchimento obrigatório.',
        value => (value && value.length >= 3) || 'Min 3 caracteres'
      ],
      dialogAddGradeLevel: false,
      loading: false,
      loadingSave: false,
      update: false,
      request: false,
      snackbar: false,
      msgSnackbar: '',
      timeout: 3000,
      currentSubject: {},
      subject: {},
      formError: {}
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },

    actions () {
      return [
        {
          label: 'Atualizar',
          action: this.updateSubject,
          icon: 'mdi-floppy',
          disabled: this.btnDisable
        },
        {
          label: 'Deletar',
          action: () => { this.dialogConfirm = true },
          icon: 'mdi-delete',
          disabled: this.subjectsDelete
        }
      ]
    }
  },
  created () {
    this.fetchAll()
    this.subjectsUpdate = !utils.checkPermissions('subjects.update-any')
    this.subjectsDelete = !utils.checkPermissions('subjects.delete')
  },
  methods: {
    async fetchAll () {
      await this.getSubject()
    },
    async getSubject () {
      this.loading = true
      this.loadingMsg = 'Carregando lista de curso/ano...'
      try {
        const response = await this.$api.getSubject(this.id)
        this.subject = response
        this.currentSubject = utils.currentData(response)
      } catch {
        // console.log('Erro ao obter levels')
      }
      this.loading = false
    },
    validate () {
      return this.$refs.form.validate()
    },
    sizes (data) {
      return utils.size(data)
    },
    checkUpdate () {
      const data = utils.newData(this.subject, this.currentSubject)
      if (utils.size(data) > 0) {
        this.btnDisable = false
      } else {
        this.btnDisable = true
      }
      return data
    },
    checkDelete () {
      this.renderComponent += 1
      this.dialogConfirm = true
    },
    async deleteSubject () {
      this.request = true
      this.loadingMsg = 'Excluindo...'
      try {
        await this.$api.disableSubject(this.id)
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Excluido com sucesso'
        this.$router.back()
      } catch (e) {
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível Excluir, tente novamente mais tarde'
        // console.log(e)
      }
      this.request = false
    },
    async updateSubject () {
      const validate = await this.validate()
      if (validate === false) {
        return false
      }
      this.request = true
      const data = this.checkUpdate()
      try {
        await this.$api.updateSubject(this.id, data)
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Atualizado com sucesso'
        this.fetchAll()
      } catch (e) {
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível atualizar, tente novamente mais tarde'
        // console.log(e)
      }
      this.request = false
    }
  }
}
</script>
