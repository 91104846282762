<template>
  <div>
    <v-card-title>
      Cadastrar {{ title }}
    </v-card-title>
    <v-card-text>
      <v-form
        ref="form"
      >
        <v-text-field
          v-model="name"
          :rules="rulesName"
          :error-messages="formError.name"
          label="Nome"
          @input="formError.name = ''"
        />
      </v-form>
    </v-card-text>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <v-btn
          color="primary"
          text
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="save()"
        >
          Salvar {{ title }}
        </v-btn>
      </div>
    </v-card-text>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    title: { type: String, default: undefined }
  },
  data: () => ({
    rulesName: [
      value => !!value || 'Preenchimento obrigatório.',
      value => (value && value.length >= 3) || 'Min 3 caracteres'
    ],
    formError: {},
    name: ''
  }),
  created () {
    this.fetchAll()
  },
  methods: {
    fetchAll () {
    },
    validate () {
      return this.$refs.form.validate()
    },
    close () {
      this.$emit('close')
    },

    async save () {
      const validate = await this.validate()
      if (validate === false) {
        return false
      }
      const data = {
        name: this.name
      }
      this.$emit('save', data)
    }
  }
}
</script>
