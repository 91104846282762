export default {
  data: () => ({
    formErrors: {}
  }),

  methods: {
    getErrors (err) {
      if (!err.response?.data?.errors) {
        throw err
      }

      this.formErrors = err.response.data.errors
      // console.log(this.formErrors)
    },

    cleanFormErrors () {
      this.formErrors = {}
    },

    clearInput (data) {
      this.formErrors[data] = ''
    }
  }
}
