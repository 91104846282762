<template>
  <ItemPage
    title="Usuários"
    size="sm"
    :actions="actions"
  >
    <v-container v-if="intranetUserView">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisa"
            single-line
            hide-details
          />
        </v-col>
      </v-row>
    </v-container>
    <v-simple-table
      v-if="intranetUserView"
      fixed-header
    >
      <template v-if="loading">
        <Load :loading-msg="loadingMsg" />
      </template>
      <div
        v-else
        class="mr-5 ml-5"
      >
        <v-data-table
          :headers="headers"
          :items="users"
          :items-per-page="15"
          :search="search"
          item-key="id"
          @click:row="editUser($event)"
        >
          <template #item.status="{ item }">
            {{ statusText(item.status) }}
          </template>
        </v-data-table>
      </div>
    </v-simple-table>
    <template v-else>
      usuário sem permissão
    </template>
    <v-dialog
      v-model="dialogAddUser"
      width="500"
      persistent
    >
      <v-card>
        <v-card-text>
          <Load
            v-if="loadingSave"
            :loading-msg="loadingSaveMsg"
          />
          <AddUser
            :key="renderComponent"
            :errors="formErrors"
            @close="dialogAddUser = false"
            @save="newIntranetUser($event)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </ItemPage>
</template>

<script>
import handlesServerValidation from '@/mixins/handles-server-validation.js'
import AddUser from '@/components/UsersAndRoles/Add_User.vue'
import Load from '@/components/Loading.vue'
import ItemPage from '@/components/pages/ItemPage.vue'
import utils from '@/api/utils.js'

export default {
  components: {
    AddUser, Load, ItemPage
  },

  mixins: [
    handlesServerValidation
  ],

  data () {
    return {
      intranetUserView: true,
      intranetUserCreate: true,
      loading: false,
      loadingSave: false,
      loadingMsg: '',
      loadingSaveMsg: '',
      users: [],
      status: '',
      msg: '',
      updateUser: false,
      dialogAddUser: false,
      idUser: undefined,
      renderComponent: 0,
      search: '',
      headers: [
        {
          text: 'Nome',
          align: 'start',
          value: 'name'
        },
        {
          text: 'E-mail',
          align: 'start',
          value: 'email'
        },
        {
          text: 'Status',
          align: 'start',
          value: 'status'
        }
      ]
    }
  },

  computed: {
    actions () {
      return [
        {
          label: 'Adicionar Usuário',
          action: this.openDialogAddUser,
          icon: 'mdi-plus',
          disabled: !this.intranetUserCreate
        }
      ]
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    fetchAll () {
      this.getUsers()
      this.intranetUserView = utils.checkPermissions('intranet-users.view-any')
      this.intranetUserCreate = utils.checkPermissions('intranet-users.create')
    },
    async getUsers () {
      this.loading = true
      this.loadingMsg = 'Carregando Lista...'
      try {
        const users = await this.$api.getUser()
        this.users = users
      } catch {
        // console.log('Erro ao listar usuários')
      }
      this.loading = false
    },
    editUser (event) {
      this.$router.push({ name: 'intranet-user.id', params: { id: event.id } })
    },
    statusText (typevalue) {
      const status = this.$api.statusType()
      return status.find(element => element.value === typevalue).statusName
    },
    openDialogAddUser () {
      this.renderComponent += 1
      this.dialogAddUser = true
    },
    async newIntranetUser (data) {
      this.loadingSave = true
      try {
        const response = await this.$api.newUser(data)
        this.loadingSave = false
        this.$router.push({ name: 'intranet-user.id', params: { id: response.id } })
      } catch (e) {
        this.loadingSave = false
        this.getErrors(e)
      }
    }
  }
}
</script>
