export const types = {
  compliant: 0,
  defaulting: 2
}
export function listTypes () {
  const data = [
    { name: 'Adimplente', value: 0 },
    { name: 'Inadimplente', value: 1 }
  ]
  return data
}

export const displayNames = {
  [types.defaulting]: 'Adimplente',
  [types.compliant]: 'Inadimplente'
}

export function getTextForDisplay (type) {
  const name = displayNames[type]

  if (name === undefined) {
    return ''
  }

  return name
}

export default {
  types,
  displayNames,
  listTypes,
  getTextForDisplay
}
