import axios from 'axios'

// Teaching systems

export async function getTeachingSystems () {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/teaching-systems')
  return response.data.data
}

export async function getTeachingSystemID (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/teaching-systems/' + id)
  return response.data.data
}

export async function getGradeLevelGroupsOfTeachingSystem (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/teaching-systems/' + id + '/grade-level-groups')
  return response.data.data
}

export default {
  getTeachingSystems,
  getTeachingSystemID,
  getGradeLevelGroupsOfTeachingSystem
}
