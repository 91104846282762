<template>
  <div>
    <template v-if="loading">
      <Load :loading-msg="loadingMsg" />
    </template>
    <template v-else>
      <v-card-title>
        Cadastro do Usuário
      </v-card-title>
      <v-form
        ref="form"
      >
        <v-text-field
          v-model="dataUser.name"
          prepend-icon="mdi-account"
          :error-messages="errors.name"
          :rules="rulesName"
          label="Nome"
          @input="errors.name = ''"
        />
        <v-text-field
          v-model="dataUser.email"
          prepend-icon="mdi-email"
          label="E-mail de acesso"
          :error-messages="errors.email"
          :rules="rulesEmail"
          @input="errors.email = ''"
        />
        <v-text-field
          v-model="dataUser.password"
          prepend-icon="mdi-lock"
          type="password"
          label="Senha"
          :error-messages="errors.password"
          :rules="rulesPassword"
          @input="errors.password = ''"
        />
        <v-switch
          v-model="dataUser.status"
          class="ma-2"
          :label="dataUser.status ? 'Ativo' : 'Inativo'"
        />
      </v-form>
      <div class="d-flex justify-space-between">
        <v-btn
          color="primary"
          text
          @click="close()"
        >
          fechar
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="save()"
        >
          Salvar
        </v-btn>
      </div>
    </template>
  </div>
</template>

<script>
import Load from '@/components/Loading.vue'

export default {
  components: {
    Load
  },
  props: {
    errors: { type: Object, default: null }
  },

  data: () => ({
    rulesName: [
      value => !!value || 'Nome é obrigatório.',
      value => (value && value.length >= 1) || 'Min 3 caracteres'
    ],
    rulesEmail: [
      v => !!v || 'E-mail é obrigatório',
      v => /.+@.+\..+/.test(v) || 'E-mail inválido'
    ],
    rulesPassword: [
      value => !!value || 'Senha é obrigatória.'
      // value => (value && value.length >= 8) || 'Min 8 caracteres'
    ],
    dataUser: {
      name: undefined,
      email: undefined,
      password: undefined,
      status: true
    },
    formError: {},
    loading: false,
    loadingMsg: ''
  }),
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    close () {
      this.$emit('close')
    },

    async save () {
      const validate = await this.validate()
      if (validate === false) {
        return false
      }
      const data = {
        name: this.dataUser.name,
        email: this.dataUser.email,
        password: this.dataUser.password,
        status: this.dataUser.status
      }
      this.$emit('save', data)
    }
  }
}
</script>
