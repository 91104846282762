<template>
  <div
    class="py-5"
    style="background-color: #f3f4f7"
  >
    <v-container
      fluid
      :class="[ size ? 'item-page-size-' + size : null]"
    >
      <v-card
        outlined
      >
        <div class="white d-flex align-center py-6 px-8">
          <v-btn
            icon
            @click="$router.back()"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <h1 class="text-h6 ml-2 d-inline">
            {{ title }}
          </h1>

          <v-spacer />

          <v-btn
            v-for="(item, idx) in buttons"
            :key="item.label"
            class="ml-2"
            text
            :disabled="item.disabled"
            :color="idx === 0 ? 'primary' : null"
            @click="item.action"
          >
            <v-icon left>
              {{ item.icon }}
            </v-icon>

            {{ item.label }}
          </v-btn>

          <v-menu
            v-if="menu && menu.length"
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-btn
                class="ml-2"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="item in menu"
                :key="item.label"
                :disabled="item.disabled || false"
                @click="item.action"
              >
                <v-list-item-icon class="mr-4">
                  <v-icon v-text="item.icon" />
                </v-list-item-icon>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <v-divider />

        <div class="white pa-8">
          <slot />
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>

/** Number of buttons to show */
const BUTTONS_COUNT = 2

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    actions: {
      type: Array,
      default: null
    },
    menuActions: {
      type: Array,
      default: null
    },
    size: {
      type: String,
      default: null
    }
  },

  computed: {
    buttons () {
      if (this.isMobile) {
        return null
      }
      if (this.actions?.length > BUTTONS_COUNT) {
        return this.actions.slice(0, BUTTONS_COUNT)
      }
      return this.actions
    },

    menu () {
      if (!this.actions) {
        return
      }

      // O menu é tudo que não coube nos buttons
      const menu = this.actions.slice(this.buttons?.length)

      if (this.menuActions) {
        menu.push(...this.menuActions)
      }

      if (!menu || !menu.length) {
        return null
      }
      return menu
    }
  },

  methods: {
    emitAction (action) {
      this.$emit('action', action)
    }
  }
}
</script>

<style>

.item-page-size-sm {
  max-width: 720px;
}

.item-page-size-md {
  max-width: 960px;
}

.item-page-size-lg {
  max-width: 1280px;
}

</style>
