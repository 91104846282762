import axios from 'axios'

// Questions

export async function getQuestionBoards () {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/question-boards')
  return response.data.data
}

export async function newQuestionBoard (data) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/question-boards', data)
  return response.data.data
}

export async function getQuestionBoard (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/question-boards/' + id)
  return response.data.data
}

export async function updateQuestionBoard (id, data) {
  const response = await axios.put(import.meta.env.VITE_API_URL + '/v1/intranet/question-boards/' + id, data)
  return response.data.data
}

export async function deleteQuestionBoard (id) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/question-boards/' + id)
  return response.data.data
}

export async function newQuestion (data) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/questions', data)
  return response.data.data
}

export async function getQuestion (id, query) {
  let url = `${import.meta.env.VITE_API_URL}/v1/intranet/questions/${id}`

  if (query) {
    url += '?' + query
  }

  const response = await axios.get(url)

  return response.data.data
}

export async function listQuestions (query) {
  let url = import.meta.env.VITE_API_URL + '/v1/intranet/questions'

  if (query) {
    url += '?' + query
  }

  const response = await axios.get(url)
  return response.data
}

export async function updateQuestion (id, data) {
  const response = await axios.put(`${import.meta.env.VITE_API_URL}/v1/intranet/questions/${id}`, data)
  return response.data.data
}

export async function deleteQuestion (id) {
  const response = await axios.delete(`${import.meta.env.VITE_API_URL}/v1/intranet/questions/${id}`)
  return response.data.data
}

export async function uploadQuestionImage (id, formData, sizeTemplate) {
  const res = await axios.post(`${import.meta.env.VITE_API_URL}/v1/intranet/questions/${id}/question-images?size_template=${sizeTemplate}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return res.data.data
}

export async function createQuestionFrom (id) {
  const response = await axios.post(import.meta.env.VITE_API_URL + `/v1/intranet/questions/${id}/create-from`)
  return response.data.data
}

export default {
  getQuestionBoards,
  newQuestionBoard,
  getQuestionBoard,
  updateQuestionBoard,
  deleteQuestionBoard,
  newQuestion,
  getQuestion,
  listQuestions,
  updateQuestion,
  deleteQuestion,
  uploadQuestionImage,
  createQuestionFrom
}
