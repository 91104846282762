import axios from 'axios'

// Roles

export async function getRoles () {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/intranet-roles')
  return response.data.data
}

export async function newRole (data) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/intranet-roles', data)
  return response.data.data
}

export async function getRole (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/intranet-roles/' + id + '?with=intranet_permissions')
  return response.data.data
}

export async function updateRole (id, data) {
  const response = await axios.put(import.meta.env.VITE_API_URL + '/v1/intranet/intranet-roles/' + id, data)
  return response.data.data
}

export default {
  getRoles,
  newRole,
  getRole,
  updateRole
}
