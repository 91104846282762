import axios from 'axios'

// Tag Types

export async function getTagTypes () {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/tag-types')
  return response.data.data
}

export async function newTagType (data) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/tag-types', data)
  return response.data.data
}

export async function getTagType (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/tag-types/' + id + '?with=tags')
  return response.data.data
}

export async function updateTagType (id, data) {
  const response = await axios.put(import.meta.env.VITE_API_URL + '/v1/intranet/tag-types/' + id, data)
  return response.data.data
}

export async function deleteTagType (id) {
  const response = await axios.delete(import.meta.env.VITE_API_URL + '/v1/intranet/tag-types/' + id)
  return response.data.data
}

export default {
  getTagTypes,
  newTagType,
  getTagType,
  updateTagType,
  deleteTagType
}
