import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    layout: '',
    intranetUser: '',
    error: {},
    snackBars: []
  },
  mutations: {
    setLayout (state, layout) {
      state.layout = layout
    },
    setUser (state, user) {
      state.intranetUser = user
    },
    setError (state, msg) {
      state.error = msg
    },
    setSnackBar (state, snackbar) {
      state.snackBars = state.snackBars.concat(snackbar)
    }
  },
  actions: {
    setSnackBar ({ commit }, snackbar) {
      snackbar.showing = true
      snackbar.color = snackbar.color || 'success'
      snackbar.text = snackbar.text || 'ok'
      commit('setSnackBar', snackbar)
    }
  },
  modules: {
  }
})
