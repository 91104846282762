import axios from 'axios'

// grade level groups

export async function getGradeLevelGroups () {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/grade-level-groups?with=gradeLevels')
  return response.data.data
}

export async function newGradeLevelGroup (data) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/grade-level-groups', data)
  return response.data.data
}

export async function getGradeLevelGroup (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/grade-level-groups/' + id + '?with=gradeLevels')
  return response.data.data
}

export async function updateGradeLevelGroup (id, data) {
  const response = await axios.put(import.meta.env.VITE_API_URL + '/v1/intranet/grade-level-groups/' + id, data)
  return response.data.data
}
export async function disableGradeLevelGroup (id) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/grade-level-groups/' + id + '/disable')
  return response.data.data
}

export default {
  getGradeLevelGroups,
  newGradeLevelGroup,
  getGradeLevelGroup,
  updateGradeLevelGroup,
  disableGradeLevelGroup
}
