<template>
  <div>
    <ItemPage
      title="Editar cargo"
      size="sm"
      :actions="actions"
    >
      <template v-if="!loading">
        <div class="d-flex flex-row">
          <v-text-field
            v-model="role.display_name"
            :disabled="!canUpdate"
            label="Nome do Cargo"
            outlined
            class="mx-1"
          />
          <v-text-field
            v-model="role.name"
            disabled
            label="Código"
            outlined
            class="mx-1"
          />
        </div>
        <div>
          <div
            v-for="permissionGroup in permissions"
            :key="permissionGroup.name"
          >
            <span> {{ permissionGroup.name }}</span>
            <div
              class="d-flex flex-row flex-wrap"
            >
              <v-checkbox
                v-for="item in permissionGroup.permissions"
                :key="item.id"
                v-model="role.intranet_permissions"
                color="primary"
                class="mx-4"
                :label="item.display_name"
                :value="item.id"
                :disabled="!canUpdate"
              />
            </div>
          </div>
        </div>
      </template>
      <Load v-else />
    </ItemPage>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ msgSnackbar }}

      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Load from '@/components/Loading.vue'
import ItemPage from '@/components/pages/ItemPage.vue'
import utils from '@/api/utils.js'

export default {
  components: {
    Load,
    ItemPage
  },
  data () {
    return {
      canUpdate: false,
      id: '',
      loading: false,
      loadingSave: false,
      update: false,
      request: false,
      snackbar: false,
      msgSnackbar: '',
      timeout: 3000,
      role: {},
      permissions: {},
      formError: []
    }
  },
  computed: {
    actions () {
      return [
        {
          label: 'Salvar',
          action: this.updateRole,
          icon: 'mdi-floppy',
          disabled: !this.canUpdate
        }
      ]
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    async fetchAll () {
      await this.start()
      await this.getRole()
      await this.getPermissions()
      this.canUpdate = utils.checkPermissions('roles.update-any')
    },
    async start () {
      this.id = await this.$route.params.id
    },
    async getRole () {
      this.loading = true
      this.loadingMsg = 'Carregando dados...'
      try {
        const response = await this.$api.getRole(this.id)
        this.role = response
        this.role.intranet_permissions = response.intranet_permissions.map(function permission (per) { return per.id })
      } catch {
        this.loading = false
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível obter o cargo, tente novamente mais tarde'
      }
      this.loading = false
    },
    async getPermissions () {
      this.loading = true
      try {
        const permissions = await this.$api.getPermissions()
        const groupsName = permissions.map((item) => item.display_group)
        const permissionsGroups = [...new Set(groupsName)].map(groupsName => ({
          name: groupsName,
          permissions: permissions.filter((item) => item.display_group === groupsName)
        }))
        permissionsGroups.forEach(group => {
          group.permissions.sort((obj1, obj2) => {
            const getPriority = (displayName) => {
              if (displayName.endsWith('.create')) return 1
              if (displayName.endsWith('.view-any')) return 2
              if (displayName.endsWith('.update-any')) return 3
              if (displayName.endsWith('.delete') || displayName.endsWith('.delete-any')) return 4
              if (displayName.endsWith('.view-own')) return 5
              if (displayName.endsWith('.update-own')) return 6
              if (displayName.endsWith('.delete-own')) return 7
              return 8
            }
            const priority1 = getPriority(obj1.name)
            const priority2 = getPriority(obj2.name)
            return priority1 - priority2
          })
        })
        this.permissions = permissionsGroups
      } catch (e) {
        this.loading = false
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível listar as permissões, tente novamente mais tarde'
      }
      this.loading = false
    },
    async updateRole () {
      this.loading = true
      this.request = true
      const data = {
        display_name: this.role.display_name,
        intranet_permissions: this.role.intranet_permissions
      }
      try {
        await this.$api.updateRole(this.id, data)
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Atualizado com sucesso'
        this.fetchAll()
      } catch (e) {
        this.request = false
        this.loading = false
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível atualizar, tente novamente mais tarde'
      }
      this.request = false
      this.loading = false
    }
  }
}
</script>
