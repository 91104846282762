import axios from 'axios'

// Topics
export async function getTopics (gradeLevelId, subjectId = null, teachingSystemId = null, withTeachingSystem = false) {
  let url = `${import.meta.env.VITE_API_URL}/v1/intranet/grade-levels/${gradeLevelId}/topics`

  const query = []
  if (subjectId) {
    query.push('subject_id=' + subjectId)
  }
  if (teachingSystemId) {
    query.push('teaching_system_id=' + teachingSystemId)
  }
  if (withTeachingSystem) {
    query.push('with=teachingSystem')
  }

  if (query.length > 0) {
    url += '?' + query.join('&')
  }

  const response = await axios.get(url)
  return response.data.data
}

export async function newTopic (gradeLevelId, data) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/grade-levels/' + gradeLevelId + '/topics', data)
  return response.data.data
}

export async function getTopic (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/topics/' + id)
  return response.data.data
}

export async function getVolumes (gradeLevel, teachingSystem) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/grade-levels/' + gradeLevel + '/volumes?teaching_system_id=' + teachingSystem)
  return response.data.data
}

export async function updateTopic (id, data) {
  const response = await axios.put(import.meta.env.VITE_API_URL + '/v1/intranet/topics/' + id, data)
  return response.data.data
}

export async function disableTopic (id) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/topics/' + id + '/disable')
  return response.data.data
}

export default {
  getTopics,
  newTopic,
  getTopic,
  updateTopic,
  disableTopic,
  getVolumes
}
