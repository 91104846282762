<template>
  <v-app style="background-color: #f3f4f7">
    <v-app-bar
      app
      clipped-left
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
      />

      <router-link
        :to="{name: 'painel'}"
        class="ml-2"
        style="height: 100%;"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.2"
          viewBox="0 0 2451 382"
          class="py-3"
          style="height: 100%;"
        >
          <g>
            <path
              d="M292.1 193.5v128.1h-64v-64H100v64H36V193.5q0-52.9 37.5-90.5 37.6-37.5 90.6-37.5 52.9 0 90.5 37.5 37.5 37.6 37.5 90.5zm-64 0q0-26.5-18.7-45.3-18.8-18.7-45.3-18.7-26.6 0-45.3 18.7Q100 167 100 193.5zm150.1-128v128q0 26.6 18.7 45.4 18.7 18.7 45.3 18.7h64V65.5h64.1v256.1H442.2q-53 0-90.5-37.5-37.6-37.6-37.6-90.6v-128z"
              style="fill:#ffd30a"
            />
            <path
              d="M697.8 129.5h-64v128.1h64q26.6 0 45.3-18.7 18.8-18.8 18.8-45.4 0-26.5-18.8-45.3-18.7-18.7-45.3-18.7z"
              style="fill:#636160;"
            />
            <path
              d="M925.3 65.5H1036v64H925.3q-26.5 0-45.3 18.7-6.2 6.3-10.4 13.3H972v64.1H869.6q4.2 7 10.4 13.3 18.8 18.7 45.3 18.7H1036v64H722.8q-.4 0-.7-.3-.4-.3-.4-.7 0-.4.2-.8.3-.3.7-.4 37.3-6.9 65.8-35.3 37.5-37.6 37.5-90.6 0-52.9-37.5-90.5-28.5-28.4-65.8-35.3-.4-.1-.7-.4-.3-.3-.2-.8 0-.4.3-.7.4-.3.8-.3z"
              style="fill:#636160;"
            />
          </g>
          <path
            d="M1323.7 47.1V322h-34.5V47.1zm55.8 70.6h31.6l.9 22.1q19.5-25.9 49.7-25.9 54.2 0 54.9 72.4V322h-33.2V187.8q0-24-8.1-34-8-10.2-24-10.2-12.5 0-22.5 8.3-9.8 8.3-15.8 21.7V322h-33.5zm197.7-49.5h33.2v49.5h30.8v27h-30.8v126.7q0 12.1 4 18.3 4 6.2 13.6 6.2 6.6 0 13.4-2.2l-.4 28.3q-11.3 3.8-23.2 3.8-19.8 0-30.2-14.2-10.4-14.2-10.4-40V144.7h-31.1v-27h31.1zm187.9 48.3.2 32.5q-7-1.3-15.1-1.3-25.7 0-35.9 28.1V322h-33.4V117.7h32.4l.6 20.7q13.4-24.5 38-24.5 7.9 0 13.2 2.6zM1930.8 322h-34.7q-3-6.7-4.3-22.3-17.8 26-45.4 26-27.7 0-43.4-15.5-15.5-15.6-15.5-43.9 0-31.2 21.2-49.5 21.1-18.3 57.9-18.7h24.6v-21.7q0-18.3-8.1-26.1-8.1-7.7-24.8-7.7-15.1 0-24.5 9.1-9.4 8.8-9.4 22.6h-33.5q0-15.7 9.3-29.8 9.2-14.4 24.9-22.5t34.9-8.1q31.4 0 47.6 15.7 16.4 15.4 16.8 45.3V278q.2 23.6 6.4 40.9zm-79.3-26.6q12.3 0 23.5-6.8 11.1-6.8 16.2-17v-48.7h-18.9q-23.8.4-37.6 10.8-13.7 10.2-13.7 28.7 0 17.3 6.9 25.3 7 7.7 23.6 7.7zm124.7-177.7h31.5l.9 22.1q19.5-25.8 49.7-25.8 54.2 0 54.9 72.3V322H2080V187.8q0-24-8.1-34-8-10.2-24-10.2-12.5 0-22.5 8.3-9.8 8.3-15.8 21.7V322h-33.4zm260.1 208.1q-38.1 0-58.5-22.7-20.4-22.8-20.8-66.8v-24.7q0-45.7 19.8-71.6 20.1-26 55.7-26 35.9 0 53.7 22.8 17.7 22.8 18.1 71.2v21.9h-114v4.7q0 32.8 12.2 47.8 12.5 14.9 35.7 14.9 14.7 0 25.9-5.5 11.3-5.7 21.1-17.7l17.4 21.1q-21.7 30.6-66.3 30.6zm-3.8-183.1q-20.7 0-30.7 14.3-10 14.2-11.4 44.2h80.5v-4.6q-1.3-29-10.6-41.5-9.1-12.4-27.8-12.4zm122.4-74.4h33.2v49.4h30.8v27h-30.8v126.7q0 12.1 4 18.3 3.9 6.3 13.6 6.3 6.6 0 13.4-2.3l-.4 28.3q-11.3 3.8-23.2 3.8-19.8 0-30.2-14.2-10.4-14.1-10.4-40V144.7h-31.2v-27h31.2z"
            fill="#636160"
          />
          <path
            fill-rule="evenodd"
            d="M1170.3 17v346.5z"
            style="stroke:#888;stroke-miterlimit:100;stroke-width:8"
          />
        </svg>
      </router-link>

      <v-spacer />

      <v-menu
        :close-on-content-click="false"
        :nudge-width="300"
        :max-width="300"
        offset-y
        bottom
      >
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-avatar color="indigo">
              <template v-if="userIntranet.avatar">
                <img
                  :src="userIntranet.avatar"
                  :alt="userIntranet.name"
                >
              </template>
              <v-icon
                v-else
                dark
              >
                mdi-account-circle
              </v-icon>
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <v-row>
            <v-col class="text-center">
              <!-- <v-hover v-model="badge"> -->
              <v-speed-dial
                v-model="fab"
                direction="bottom"
                :open-on-hover="false"
                transition="slide-y-reverse-transition"
              >
                <template #activator>
                  <v-btn
                    v-model="fab"
                    color="blue darken-2"
                    dark
                    fab
                  >
                    <v-avatar
                      v-if="userIntranet.avatar"
                      size="62"
                    >
                      <img
                        :src="userIntranet.avatar"
                        :alt="userIntranet.name"
                      >
                    </v-avatar>
                    <v-icon
                      v-else
                    >
                      mdi-camera
                    </v-icon>
                  </v-btn>
                </template>
                <v-btn
                  v-if="!userIntranet.avatar"
                  fab
                  dark
                  small
                  color="indigo"
                  @click="openDialogAvatar()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn
                  v-if="userIntranet.avatar"
                  fab
                  dark
                  small
                  color="red"
                  @click="openDialogAvatar()"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-speed-dial>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <p> {{ userIntranet.name }}</p>
              <v-btn
                rounded
                outlined
                color="indigo"
                @click="openDialogEditMe"
              >
                Gerenciar conta
              </v-btn>
            </v-col>
          </v-row>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              rounded
              outlined
              text
              color="primary"
              @click="logout()"
            >
              Sair
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      clipped
      app
    >
      <NavMenu />
    </v-navigation-drawer>
    <v-dialog
      v-model="dialogEditPerfil"
      width="500"
      persistent
    >
      <v-card>
        <v-card-text>
          <Editperfil
            :key="renderComponent"
            @close="dialogEditPerfil = false"
            @avatar="openDialogAvatar"
            @update="updateMe($event)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogEditAvatar"
      width="400"
      persistent
    >
      <v-card>
        <v-card-text>
          <EditAvatar
            :key="renderComponent"
            :user="userIntranet"
            @close="dialogEditAvatar = false"
            @delete="deleteAvatar"
            @upload="uploadAvatar($event)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script>
import Editperfil from '@/components/Edit_Me.vue'
import EditAvatar from '@/components/Edit_avatar.vue'
import NavMenu from '@/components/NavMenu.vue'
import utils from '@/api/utils.js'

export default {
  components: {
    Editperfil,
    EditAvatar,
    NavMenu
  },

  data: () => ({
    badge: false,
    actionAvatar: false,
    userIntranet: {},
    drawer: true,
    fab: false,
    renderComponent: 0,
    dialogEditPerfil: false,
    dialogEditAvatar: false,
    dialogDeleteAvatar: false,
    errors: [],
    stateErrors: []
  }),
  created () {
    this.fetchAll()
  },
  methods: {
    async fetchAll () {
      await this.checkPermission()
      await this.getIntranetUser()
    },
    getErrors (data) {
      if (data.data.errors) {
        this.errors = utils.setErrorsData(data.data.errors)
        this.stateErrors = utils.setStateErrors(data.data.errors)
      } else {
        throw (data)
      }
    },
    checkPermission (permission) {
      return utils.checkPermissions(permission)
    },
    async getIntranetUser () {
      try {
        await this.$api.me()
      } catch (e) {
        // console.log(e)
      }
      this.userIntranet = utils.getUser()
    },
    openDialogAvatar () {
      this.renderComponent += 1
      this.dialogEditAvatar = true
    },
    openDialogEditMe () {
      this.renderComponent += 1
      this.dialogEditPerfil = true
    },
    async updateMe (data) {
      try {
        await this.$api.updateUser(data, this.userIntranet.id)
        this.dialogEditPerfil = false
        this.fetchAll()
      } catch (e) {
        this.getErrors(e.response)
      }
    },
    async uploadAvatar (data) {
      try {
        await this.$api.sendAvatar(data, this.userIntranet.id)
        this.dialogEditPerfil = false
        this.dialogEditAvatar = false
        this.fetchAll()
      } catch (e) {
        this.getErrors(e.response)
      }
    },
    async deleteAvatar () {
      try {
        await this.$api.deleteAvatar(this.userIntranet.id)
        this.dialogEditPerfil = false
        this.dialogEditAvatar = false
        this.fetchAll()
      } catch (e) {
        this.getErrors(e.response)
      }
    },
    async logout () {
      try {
        await this.$api.logout()
        this.$router.push({ name: 'Login' })
      } catch (e) {
        this.getErrors(e.response)
      }
    }
  }
}
</script>
