import axios from '@/axios'

import materialRequests from '@/api/requests/material.js'
import institutionRequests from '@/api/requests/institution.js'
import roleRequests from '@/api/requests/role.js'
import intranetUserRequests from '@/api/requests/intranetUser.js'
import tagRequests from '@/api/requests/tag.js'
import tagTypeRequests from '@/api/requests/tagType.js'
import questionRequests from '@/api/requests/question.js'
import subjectRequests from '@/api/requests/subject.js'
import topicRequests from '@/api/requests/topic.js'
import gradelevelsRequests from '@/api/requests/gradelevel.js'
import gradelevelGroupRequests from '@/api/requests/gradeLevelGroup.js'
import mediaContentRequests from '@/api/requests/mediaContent.js'
import authRequests from '@/api/requests/auth.js'
import articleRequests from '@/api/requests/articles.js'
import audeExamRequests from '@/api/requests/audeExam.js'

import portalUserRequests from '@/api/requests/portalUser.js'
import teachingSystemRequests from '@/api/requests/teachingSystem.js'

export default {
  axios,
  // login /logout / permissions / me
  ...authRequests,

  statusType () {
    const types = [
      { statusName: 'Inativo', value: 0 },
      { statusName: 'Ativo', value: 1 }
    ]
    return types
  },
  // Portal Users
  ...portalUserRequests,
  // Material text books
  ...materialRequests,
  // articles
  ...articleRequests,
  // aude exams
  ...audeExamRequests,

  // Institutions
  ...institutionRequests,

  // Roles
  ...roleRequests,

  // intranetUser
  ...intranetUserRequests,

  // media contents
  ...mediaContentRequests,

  // settings portal
  // grade level group
  ...gradelevelGroupRequests,
  // grade level
  ...gradelevelsRequests,
  // topics
  ...topicRequests,
  // Teaching Systems
  ...teachingSystemRequests,
  // subjects
  ...subjectRequests,
  // Question Board
  ...questionRequests,
  // tags-types
  ...tagTypeRequests,
  // tags
  ...tagRequests

}
