<template>
  <ItemPage
    title="Tags - Tag"
    size="md"
    :actions="actions"
  >
    <v-form
      ref="form"
    >
      <v-container>
        <v-row>
          <v-col
            cols="6"
            class="mr-auto"
          >
            <v-text-field
              v-model="tag.name"
              :disabled="!tagsUpdate"
              :rules="rulesName"
              label="Nome"
              @input="checkUpdate"
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="tag.tag_type_id"
              :disabled="!tagsUpdate"
              :items="tagtypes"
              :rules="rulasTagtype"
              item-text="name"
              item-value="id"
              label="Tipos de Tag"
              @change="checkUpdate"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-dialog
      v-model="dialogConfirm"
      width="500"
      persistent
    >
      <v-card>
        <template v-if="request">
          <Load :loadingmsg="loadingMsg" />
        </template>
        <template v-else>
          <DialogConfirm
            :key="renderComponent"
            title="a Tag"
            :name="tag.name"
            @close="dialogConfirm = false"
            @confirm="deleteTag($event)"
          />
        </template>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ msgSnackbar }}

      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import DialogConfirm from '@/components/Dialog_confirm.vue'
import ItemPage from '@/components/pages/ItemPage.vue'
import utils from '@/api/utils.js'

export default {
  components: {
    Load,
    DialogConfirm,
    ItemPage
  },
  data () {
    return {
      tagsUpdate: false,
      tagsDelete: false,
      dialogConfirm: false,
      btnDisable: true,
      renderComponent: 0,
      rulesName: [
        value => !!value || 'Preenchimento obrigatório.'
      ],
      rulasTagtype: [
        value => !!value || 'Preenchimento obrigatório.'
      ],
      loading: false,
      tagtypes: [],
      currentTag: {},
      tag: {},
      loadingSave: false,
      update: false,
      request: false,
      snackbar: false,
      msgSnackbar: '',
      timeout: 3000,
      formError: []
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },

    actions () {
      return [
        {
          label: 'Atualizar',
          action: this.updateTag,
          icon: 'mdi-floppy',
          disabled: this.btnDisable
        },
        {
          label: 'Deletar',
          action: () => { this.dialogConfirm = true },
          icon: 'mdi-delete',
          disabled: !this.tagsDelete
        }
      ]
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    async fetchAll () {
      await this.getTagTypes()
      await this.getTag()
      this.tagsUpdate = utils.checkPermissions('tags.update-any')
      this.tagsDelete = utils.checkPermissions('tags.delete')
    },
    async getTagTypes () {
      try {
        this.tagtypes = await this.$api.getTagTypes()
      } catch (e) {
        // console.log(e)
      }
    },
    async getTag () {
      this.loading = true
      this.loadingMsg = 'Carregando lista de tópicos...'
      try {
        const response = await this.$api.getTag(this.id)
        this.tag = response
        this.currentTag = utils.currentData(response)
      } catch {
        // console.log('Erro ao obter levels')
      }
      this.loading = false
    },
    sizes (data) {
      return utils.size(data)
    },
    checkUpdate () {
      const data = utils.newData(this.tag, this.currentTag)
      if (utils.size(data) > 0) {
        this.btnDisable = false
      } else {
        this.btnDisable = true
      }
      return data
    },
    checkDelete () {
      this.renderComponent += 1
      this.dialogConfirm = true
    },
    async deleteTag () {
      this.request = true
      this.loadingMsg = 'Excluindo...'
      try {
        await this.$api.deleteTag(this.id)
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Excluido com sucesso'
        this.$router.back()
      } catch (e) {
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível Excluir, tente novamente mais tarde'
        // console.log(e)
      }
      this.request = false
    },
    async updateTag () {
      const validate = await this.validate()
      if (validate === false) {
        return false
      }
      this.request = true
      const data = this.checkUpdate()
      try {
        await this.$api.updateTag(this.id, data)
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Atualizado com sucesso'
        this.fetchAll()
      } catch (e) {
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível atualizar, tente novamente mais tarde'
        // console.log(e)
      }
      this.request = false
    },
    validate () {
      return this.$refs.form.validate()
    }
  }
}
</script>
