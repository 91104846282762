<template>
  <div class="container">
    <div class="row d-flex justify-space-between mt-10">
      <div class="col-auto">
        <p>Contrato #{{ contract.id }} </p>
      </div>
      <div class="col-auto">
        <v-btn
          color="red"
          :disabled="disableUpdate"
          @click="saveContract"
        >
          Salvar
        </v-btn>
      </div>
    </div>
    <div class="row d-flex justify-space-between mt-10">
      <div class="col-auto">
        <v-menu
          ref="menu1"
          v-model="menuSigned_at"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="editContract.signed_at"
              label="Data de assinatura"
              prepend-icon="mdi-calendar"
              :state="stateErrors.signed_at"
              :error-messages="errors.signed_at"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            ref="picker"
            v-model="editContract.signed_at"
            min="1990-01-01"
            @change="menuSigned_at = false"
          />
        </v-menu>
      </div>
      <div class="col-auto">
        <v-menu
          ref="menu"
          v-model="menuStarts_at"
          :close-on-content-click="false"
          transition="scale-transition"
          :state="true"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="editContract.starts_at"
              label="Data de inicio"
              prepend-icon="mdi-calendar"
              :state="true"
              :error-messages="errors.starts_at"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            ref="picker"
            v-model="editContract.starts_at"
            :state="true"
            :error-messages="errors.starts_at"
            min="1990-01-01"
            @change="menuStarts_at = false"
          />
        </v-menu>
      </div>
      <div class="col-auto">
        <v-menu
          ref="menu"
          v-model="menuExpires_at"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="editContract.expires_at"
              :label="editContract.starts_at === '' ? 'Selecione a data inicial' :'Data final'"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            ref="picker"
            v-model="editContract.expires_at"
            :min="editContract.starts_at"
            @change="menuExpires_at = false"
          />
        </v-menu>
      </div>
      <div class="col-auto">
        <v-menu
          ref="menu"
          v-model="menuRenews_at"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="editContract.renews_at"
              :label="editContract.starts_at === '' ? 'Selecione a data inicial' : editContract.expires_at === '' ? 'Selecione a data final' : 'Data de renovação'"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            ref="picker"
            v-model="editContract.renews_at"
            :min="editContract.expires_at"
            @change="menuRenews_at = false"
          />
        </v-menu>
      </div>
    </div>
    <v-tabs
      v-model="tab"
      background-color="grey lighten-5"
      fixed-tabs
      show-arrows
    >
      <v-tabs-slider />
      <v-tab href="#pec">
        Sistema Pec
      </v-tab>
      <v-tab href="#m4">
        M4
      </v-tab>
      <v-tab href="#pequenino">
        Pequenino
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item id="pec">
        <div
          v-for="levelGroup in gradeLevelGroupPec"
          :key="levelGroup.id"
        >
          <LevelGroupItems
            v-if="showList"
            :contractid="contract.id"
            :system="1"
            :levelgroupname="levelGroup.name"
            :levelgroups="levelGroup"
            :items="editContract.contract_grade_level_items"
            @reload="reloadContract"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>
    <v-tabs-items v-model="tab">
      <v-tab-item id="m4">
        <div
          v-for="levelGroup in gradeLevelGroupM4"
          :key="levelGroup.id"
        >
          <LevelGroupItems
            v-if="showList"
            :contractid="contract.id"
            :system="2"
            :levelgroupname="levelGroup.name"
            :levelgroups="levelGroup"
            :items="editContract.contract_grade_level_items"
            @reload="reloadContract"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>
    <v-tabs-items v-model="tab">
      <v-tab-item id="pequenino">
        <div
          v-for="levelGroup in gradeLevelGroupPeq"
          :key="levelGroup.id"
        >
          <LevelGroupItems
            v-if="showList"
            :contractid="contract.id"
            :system="3"
            :levelgroupname="levelGroup.name"
            :levelgroups="levelGroup"
            :items="editContract.contract_grade_level_items"
            @reload="reloadContract"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ msgSnackbar }}

      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import LevelGroupItems from '@/components/schools/contracts/LevelGroupItems.vue'
import dayjs from '@/dayjs'

export default {
  components: {
    LevelGroupItems
  },
  props: {
    contract: { type: Object, default: () => {} },
    gradelevelgroups: { type: Array, default: () => [] },
    systems: { type: Array, default: () => [] }
  },
  data: () => ({
    tab: 'dados',
    showList: false,
    menuSigned_at: false,
    menuStarts_at: false,
    menuExpires_at: false,
    menuRenews_at: false,
    snackbar: false,
    msgSnackbar: '',
    timeout: 4000,
    editContract: {},
    gradeLevelGroupPec: {},
    gradeLevelGroupM4: {},
    gradeLevelGroupPeq: {},
    system: 0,
    disableUpdate: true,
    renderComponent: 0,
    errors: [],
    stateErrors: []
  }),
  watch: {
    editContract () {
      this.editContract.starts_at = this.returnData(this.editContract.starts_at)
      this.editContract.expires_at = this.returnData(this.editContract.expires_at)
      this.editContract.renews_at = this.returnData(this.editContract.renews_at)
      this.editContract.signed_at = this.returnData(this.editContract.signed_at)
    },
    menuSigned_at () {
      if (this.menuSigned_at === false) {
        this.verifyUpdate()
      }
    },
    menuStarts_at () {
      if (this.menuStarts_at === false) {
        this.verifyUpdate()
      }
    },
    menuExpires_at () {
      if (this.menuExpires_at === false) {
        this.verifyUpdate()
      }
    },
    menuRenews_at () {
      if (this.menuRenews_at === false) {
        this.verifyUpdate()
      }
    }
  },
  async created () {
    this.contract.starts_at = this.returnData(this.contract.starts_at)
    this.contract.expires_at = this.returnData(this.contract.expires_at)
    this.contract.renews_at = this.returnData(this.contract.renews_at)
    this.contract.signed_at = this.returnData(this.contract.signed_at)
    await this.getContract(this.contract.id)
    this.gradeLevelGroupPec = this.separa(await this.getGradeLevelGroups(1))
    this.gradeLevelGroupM4 = this.separa(await this.getGradeLevelGroups(2))
    this.gradeLevelGroupPeq = this.separa(await this.getGradeLevelGroups(3))
  },
  methods: {
    setSystem () {
      this.listItems()
    },
    async getContract (id) {
      try {
        this.editContract = await this.$api.getContract(id)
        this.showList = true
      } catch (e) {
        // console.log(e.response)
      }
    },
    listItems () {
      this.renderComponent += 1
    },
    async getGradeLevelGroups (teachingSystem) {
      try {
        return await this.$api.getGradeLevelGroupsOfTeachingSystem(teachingSystem)
      } catch {
        // console.log('Erro ao obter os seguimentos')
      }
    },
    separa (glgts) {
      const array = glgts.map((item) => item.id)
      return this.gradelevelgroups.filter((item) => {
        if (array.includes(item.id)) {
          return item
        }
      })
    },

    verifyUpdate () {
      if (this.contract.starts_at !== this.editContract.starts_at ||
      this.contract.expires_at !== this.editContract.expires_at ||
      this.contract.renews_at !== this.editContract.renews_at ||
      this.contract.signed_at !== this.editContract.signed_at) {
        this.disableUpdate = false
      } else {
        this.disableUpdate = true
      }
    },
    reloadContract () {
      this.getContract(this.contract.id)
    },
    async saveContract () {
      const data = {
        starts_at: this.contract.starts_at,
        expires_at: this.contract.expires_at,
        renews_at: this.contract.renews_at,
        signed_at: this.contract.signed_at

      }
      try {
        await this.$api.updateContract(this.contract.id, data)
        await this.getContract(this.contract.id)
      } catch (e) {
        // console.log(e.response)
      }
      this.disableUpdate = true
      this.$store.dispatch('setSnackBar', { text: 'Contrato atualizado' })
    },
    returnData (data) {
      return dayjs(data).format('YYYY-MM-DD')
    },
    dayjs
  }
}
</script>
