<template>
  <ItemPage
    title="Segmentos"
    size="sm"
    :actions="actions"
  >
    <template
      v-if="gradeLevelGroupsView"
    >
      <v-simple-table fixed-header>
        <template v-if="loading">
          <Load :loadingmsg="loadingMsg" />
        </template>
        <div
          v-else
        >
          <v-data-table
            :headers="headers"
            :items="contents"
            :items-per-page="10"
            :search="search"
            item-key="id"
            @click:row="editGradeLevelGroup($event)"
          />
        </div>
      </v-simple-table>
    </template>
    <template v-else>
      usuário sem permissão
    </template>
    <v-dialog
      v-model="dialogAddGradeLevelGroup"
      width="500"
      persistent
    >
      <v-card>
        <template v-if="request">
          <Load :loadingmsg="loadingMsg" />
        </template>
        <template v-else>
          <AddSettings
            :key="renderComponent"
            title="Seguimento"
            @close="dialogAddGradeLevelGroup = false"
            @save="newGradeLevelGroup($event)"
          />
        </template>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ msgSnackbar }}

      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import AddSettings from '@/components/settings/Add_Settings.vue'
import utils from '@/api/utils.js'
import ItemPage from '@/components/pages/ItemPage.vue'

export default {
  components: {
    Load,
    ItemPage,
    AddSettings
  },
  data () {
    return {
      gradeLevelGroupsView: false,
      gradeLevelGroupsCreate: false,
      loading: false,
      loadingMsg: '',
      request: false,
      dialogAddGradeLevelGroup: false,
      renderComponent: 0,
      contents: [],
      snackbar: false,
      msgSnackbar: '',
      timeout: 3000,
      search: '',
      headers: [
        {
          text: 'ID',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Nome',
          align: 'start',
          value: 'name'
        },
        {
          text: 'Abreviatura',
          align: 'start',
          value: 'short_name'
        },
        {
          text: 'Ordem',
          align: 'start',
          value: 'order'
        }
      ]
    }
  },
  computed: {
    actions () {
      return [
        {
          label: 'Adicionar segmentos',
          action: this.openDialogAddGradeLevelGroup,
          icon: 'mdi-plus',
          disabled: !this.gradeLevelGroupsCreate
        }
      ]
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    fetchAll () {
      this.getGradeLevelGroup()
      this.gradeLevelGroupsView = utils.checkPermissions('grade-level-groups.view-any')
      this.gradeLevelGroupsCreate = utils.checkPermissions('grade-level-groups.create')
    },
    async getGradeLevelGroup () {
      this.loading = true
      this.loadingMsg = 'Carregando Lista...'
      try {
        const content = await this.$api.getGradeLevelGroups()
        this.contents = content
      } catch {
        // console.log('Erro ao listar Segmentos')
      }
      this.loading = false
    },
    editGradeLevelGroup (event) {
      this.$router.push({ name: 'segmentos-id', params: { id: event.id } })
    },
    openDialogAddGradeLevelGroup () {
      this.renderComponent += 1
      this.dialogAddGradeLevelGroup = true
    },
    async newGradeLevelGroup (data) {
      this.request = true
      this.loadingMsg = 'Salvando...'
      try {
        await this.$api.newGradeLevelGroup(data)
        this.request = false
        this.dialogAddGradeLevelGroup = false
        this.snackbar = true
        this.msgSnackbar = 'Salvo com sucesso'
        this.fetchAll()
      } catch (e) {
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível salvar, tente novamente mais tarde'
        // console.log(e)
      }
      this.request = false
    }
  }
}
</script>
