<template>
  <ItemPage
    title="Banca"
    size="sm"
    :actions="actions"
  >
    <v-text-field
      v-model="questionBoard.name"
      :rules="rulesName"
      :disabled="!questionBoadsUpdate"
      label="Nome"
      @input="checkUpdate"
    />
    <v-dialog
      v-model="dialogConfirm"
      width="500"
      persistent
    >
      <v-card>
        <template v-if="request">
          <Load :loadingmsg="loadingMsg" />
        </template>
        <template v-else>
          <DialogConfirm
            :key="renderComponent"
            title="a banca"
            :name="questionBoard.name"
            @close="dialogConfirm = false"
            @confirm="deleteQuestionBoard($event)"
          />
        </template>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ msgSnackbar }}

      <template #action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </ItemPage>
</template>

<script>
import Load from '@/components/Loading.vue'
import DialogConfirm from '@/components/Dialog_confirm.vue'
import ItemPage from '@/components/pages/ItemPage.vue'
import utils from '@/api/utils.js'

export default {
  components: {
    DialogConfirm,
    ItemPage,
    Load
  },
  data () {
    return {
      questionBoadsUpdate: false,
      questionBoadsDelete: false,
      dialogConfirm: false,
      btnDisable: true,
      renderComponent: 0,
      rulesName: [
        value => !!value || 'Preenchimento obrigatório.',
        value => (value && value.length >= 3) || 'Min 3 caracteres'
      ],
      dialogAddGradeLevel: false,
      loading: false,
      loadingSave: false,
      update: false,
      request: false,
      snackbar: false,
      msgSnackbar: '',
      timeout: 3000,
      currentQuestionBoard: {},
      questionBoard: {},
      formError: {}
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },

    actions () {
      return [
        {
          label: 'Atualizar',
          action: this.updateQuestionBoard,
          icon: 'mdi-floppy',
          disabled: this.btnDisable
        },
        {
          label: 'Deletar',
          action: () => { this.dialogConfirm = true },
          icon: 'mdi-delete',
          disabled: !this.questionBoadsDelete
        }
      ]
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    async fetchAll () {
      await this.getQuestionBoard()
      this.questionBoadsUpdate = utils.checkPermissions('question-boards.update-any')
      this.questionBoadsDelete = utils.checkPermissions('question-boards.delete')
    },
    async getQuestionBoard () {
      this.loading = true
      this.loadingMsg = 'Carregando...'
      try {
        const response = await this.$api.getQuestionBoard(this.id)
        this.questionBoard = response
        this.currentQuestionBoard = utils.currentData(response)
      } catch {
        // console.log('Erro ao obter banca')
      }
      this.loading = false
    },
    validate () {
      return this.$refs.form.validate()
    },
    sizes (data) {
      return utils.size(data)
    },
    checkUpdate () {
      const data = utils.newData(this.questionBoard, this.currentQuestionBoard)
      if (utils.size(data) > 0) {
        this.btnDisable = false
      } else {
        this.btnDisable = true
      }
      return data
    },
    checkDelete () {
      this.renderComponent += 1
      this.dialogConfirm = true
    },
    async deleteQuestionBoard () {
      this.request = true
      this.loadingMsg = 'Excluindo...'
      try {
        await this.$api.deleteQuestionBoard(this.id)
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Excluido com sucesso'
        this.$router.back()
      } catch (e) {
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível Excluir, tente novamente mais tarde'
        // console.log(e)
      }
      this.request = false
    },
    async updateQuestionBoard () {
      const validate = await this.validate()
      if (validate === false) {
        return false
      }
      this.request = true
      const data = this.checkUpdate()
      try {
        await this.$api.updateQuestionBoard(this.id, data)
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Atualizado com sucesso'
        this.fetchAll()
      } catch (e) {
        this.request = false
        this.snackbar = true
        this.msgSnackbar = 'Não foi possível atualizar, tente novamente mais tarde'
        // console.log(e)
      }
      this.request = false
    }
  }
}
</script>
