<template>
  <div>
    <v-card-title>
      Cadastrar {{ title }}
    </v-card-title>
    <v-card-text>
      <v-form
        ref="form"
      >
        <v-text-field
          v-model="name"
          :rules="rulesName"
          :error-messages="formError.name"
          label="Nome"
          @input="formError.name = ''"
        />
        <v-select
          v-model="tagtype"
          :items="tagtypes"
          :rules="rulesTagType"
          item-text="name"
          item-value="id"
          label="Tipo de Tag"
        />
      </v-form>
    </v-card-text>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <v-btn
          color="primary"
          text
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="save()"
        >
          Salvar {{ title }}
        </v-btn>
      </div>
    </v-card-text>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    title: { type: String, default: undefined },
    id: { type: Number, default: undefined }
  },
  data: () => ({
    rulesName: [
      value => !!value || 'Preenchimento obrigatório.',
      value => (value && value.length >= 3) || 'Min 3 caracteres'
    ],
    rulesTagType: [
      value => !!value || 'Preenchimento obrigatório.'
    ],
    formError: [],
    tagtypes: [],
    tagtype: '',
    name: ''
  }),
  created () {
    this.fetchAll()
  },
  methods: {
    async fetchAll () {
      await this.getTagTypes()
    },
    async getTagTypes () {
      try {
        this.tagtypes = await this.$api.getTagTypes()
      } catch (e) {
        // console.log(e)
      }
    },
    validate () {
      return this.$refs.form.validate()
    },
    close () {
      this.$emit('close')
    },

    async save () {
      const validate = await this.validate()
      if (validate === false) {
        return false
      }
      const data = {
        name: this.name,
        tag_type_id: this.tagtype
      }
      this.$emit('save', data)
    }
  }
}
</script>
