<template>
  <div>
    <template>
      <v-card-title>
        Cadastro do Cargos
      </v-card-title>
      <v-form
        ref="form"
      >
        <v-text-field
          v-model="role.display_name"
          prepend-icon="mdi-account"
          label="Nome do cargo"
          :rules="rulesName"
          @input="inputCodigo"
        />
        <v-text-field
          v-model="role.name"
          dense
          filled
          prepend-icon="mdi-qrcode"
          label="Código"
        />
      </v-form>
      <div class="d-flex justify-space-between">
        <v-btn
          color="primary"
          text
          @click="close()"
        >
          fechar
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="save()"
        >
          Salvar
        </v-btn>
      </div>
    </template>
  </div>
</template>

<script>
import slugify from 'slugify'

export default {
  components: {
    // Load
  },

  data: () => ({
    rulesName: [
      value => !!value || 'Nome é obrigatório.',
      value => (value && value.length > 2) || 'Min 3 caracteres'
    ],
    role: {
      display_name: undefined,
      name: undefined
    }
  }),
  created () {
    // this.verifica()
  },
  methods: {
    inputCodigo () {
      this.role.name = slugify(this.role.display_name).toLowerCase()
    },
    validate () {
      return this.$refs.form.validate()
    },
    close () {
      this.$emit('close')
    },

    async save () {
      const validate = await this.validate()
      if (validate === false) {
        return false
      }
      const data = {
        display_name: this.role.display_name,
        name: this.role.name
      }
      this.$emit('save', data)
    }
  }
}
</script>
