<template>
  <div>
    <v-card-title>
      Cadastrar {{ title }}
    </v-card-title>
    <v-card-text>
      <v-form
        ref="form"
      >
        <v-text-field
          v-model="name"
          :rules="rulesName"
          :error-messages="formError.name"
          label="Nome"
          @input="formError.name = ''"
        />
        <v-text-field
          v-model="short_name"
          :rules="[() => (name.length < 20 || short_name.length > 1) || 'Nome maior que 20 caracteres, inclua sigla(min. 2 caracteres)', () => (short_name === '' || short_name.length <21 && short_name.length > 1 ) || 'inclua sigla(min. 2, max 20 caracteres)']"
          :error-messages="formError.short_name"
          :counter="20"
          maxlength="20"
          label="Sigla / Abreviatura (opcional)"
          @input="formError.short_name = ''"
        />
      </v-form>
    </v-card-text>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <v-btn
          color="primary"
          text
          @click="close()"
        >
          Fechar
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="save()"
        >
          Salvar {{ title }}
        </v-btn>
      </div>
    </v-card-text>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    title: { type: String, default: undefined }
  },
  data: () => ({
    rulesName: [
      value => !!value || 'Preenchimento obrigatório.',
      value => (value && value.length >= 3) || 'Min 3 caracteres'
    ],
    formError: {},
    name: '',
    short_name: ''
  }),
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    close () {
      this.$emit('close')
    },

    async save () {
      const validate = await this.validate()
      if (validate === false) {
        return false
      }
      const data = {
        name: this.name
      }
      if (this.short_name) {
        data.short_name = this.short_name
      }
      this.$emit('save', data)
    }
  }
}
</script>
