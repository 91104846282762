import axios from 'axios'

// Grade Levels

export async function getGradeLevels (gradeLevelGroupId) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/grade-level-groups/' + gradeLevelGroupId + '/grade-levels')
  return response.data.data
}

export async function newGradeLevel (gradeLevelGroupId, data) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/grade-level-groups/' + gradeLevelGroupId + '/grade-levels', data)
  return response.data.data
}

export async function getGradeLevel (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/grade-levels/' + id + '?with=topics,topics.teachingSystem')
  return response.data.data
}

export async function updateGradeLevel (id, data) {
  const response = await axios.put(import.meta.env.VITE_API_URL + '/v1/intranet/grade-levels/' + id, data)
  return response.data.data
}

export async function disableGradeLevel (id) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/grade-levels/' + id + '/disable')
  return response.data.data
}

export async function getSubjectsOfGradeLevel (gradeLevelId, teachingSystemId) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/grade-levels/' + gradeLevelId + '/subjects?teaching_system_id=' + teachingSystemId)
  return response.data.data
}

export async function getSubjectsOfGradeLevelWithTopics (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/grade_levels/' + id + '/subjects?with=topics')
  return response.data.data
}

export default {
  getGradeLevels,
  newGradeLevel,
  getGradeLevel,
  updateGradeLevel,
  disableGradeLevel,
  getSubjectsOfGradeLevel,
  getSubjectsOfGradeLevelWithTopics
}
