<template>
  <v-container
    class="fill-height justify-center align-center grey--text text--darken-2"
    fluid
  >
    Utilize o menu ao lado para navegar.
  </v-container>
</template>

<script>

export default {
  name: 'Painel',
  components: {
  },
  created () {
  },
  methods: {
  }
}
</script>
