<template>
  <ItemPage
    title="Escolas"
    size="sm"
    :actions="actions"
  >
    <div
      v-if="institutionsView"
      class="fill-height"
    >
      <div class="mx-4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisa"
          single-line
          hide-details
        />
      </div>
      <v-simple-table fixed-header>
        <template v-if="loading">
          <Load :loading-msg="loadingMsg" />
        </template>
        <div
          v-else
          class="mr-5 ml-5"
        >
          <v-data-table
            :headers="headers"
            :items="schools"
            :items-per-page="5"
            :search="search"
            item-key="id"
            @click:row="editSchool($event)"
          />
        </div>
      </v-simple-table>
      <v-dialog
        v-model="dialogEditschool"
        width="500"
        persistent
      >
        <v-card>
          <template v-if="loadingSave">
            <Load :loading-msg="loadingSaveMsg" />
          </template>

          <v-card-text v-else>
            <Editschool
              :key="renderComponent"
              :idschool="idschool"
              :updatemode="updateschool"
              :msg="msg"
              @getAction="setAction($event)"
            />
            <!-- </template> -->
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <template v-else>
      usuário sem permissão
    </template>
  </ItemPage>
</template>

<script>
import Editschool from '../components/Edit_school.vue'
import Load from '../components/Loading.vue'
import ItemPage from '@/components/pages/ItemPage.vue'
import utils from '@/api/utils.js'

export default {
  components: {
    Editschool,
    Load,
    ItemPage
  },
  data () {
    return {
      institutionsView: false,
      institutionsCreate: false,
      item: '',
      newSave: false,
      loading: false,
      loadingSave: false,
      loadingMsg: '',
      loadingSaveMsg: '',
      schools: [],
      status: '',
      msg: '',
      updateschool: false,
      dialogEditschool: false,
      dialogresposta: false,
      idschool: undefined,
      renderComponent: 0,
      form: {
        // email: '',
        name: ''
      },
      search: '',
      iconEdit: '<v-icon left>mdi-text-box-plus-outline</v-icon>',
      headers: [
        {
          text: 'Código',
          align: 'start',
          value: 'code'
        },
        {
          text: 'Nome',
          align: 'start',
          value: 'name'
        }
      ]
    }
  },
  computed: {
    actions () {
      return [
        {
          label: 'Adicionar Escola',
          action: this.formSchool,
          icon: 'mdi-plus',
          disabled: !this.institutionsCreate
        }
      ]
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    fetchAll () {
      this.getSchool()
      this.institutionsView = utils.checkPermissions('institutions.view-any')
      this.institutionsCreate = utils.checkPermissions('institutions.create')
    },
    checkPermission (permission) {
      return utils.checkPermissions(permission)
    },
    async getSchool () {
      this.loading = true
      this.loadingMsg = 'Carregando Lista...'
      try {
        const school = await this.$api.getSchool()
        this.schools = school
      } catch {
        // console.log('Erro ao listar escolas')
      }
      this.loading = false
    },
    editSchool (event) {
      this.$router.push({ name: 'institution.id', params: { id: event.id } })
    },
    searchschool (event) {
      this.formSchool(event.id)
    },
    formSchool (value) {
      this.renderComponent += 1
      if (value) {
        this.editschool = true
        this.idschool = value
      } else {
        this.editschool = false
      }
      this.dialogEditschool = true
    },

    setAction (response) {
      if (response.action === 'save') {
        this.saveformSchool(response)
      } else if (response.action === 'update') {
        this.updateformSchool(response)
      } else {
        this.exitformSchool()
      }
    },
    async updateformSchool (schooldata) {
      this.loadingSave = true
      const data = {
        name: schooldata.school.name
      }
      this.updateschool = false
      try {
        await this.$api.updateSchool(data, this.idschool)
        this.updateschool = false
        this.msg = 'Cadastro atualizado com sucesso'
        this.getSchool()
      } catch {
        this.msg = 'Erro na atualização'
        this.updateschool = true
      }
      this.loadingSave = false
    },

    async saveformSchool (schooldata) {
      this.loadingSave = true
      const data = {
        // email: this.form.email,
        name: schooldata.school.name
      }
      this.updateschool = false
      try {
        await this.$api.saveSchool(data)
        this.updateschool = false
        this.msg = 'Cadastrado com sucesso'
        this.getSchool()
      } catch {
        this.msg = 'Erro ao tentar salvar'
      }
      this.loadingSave = false
    },
    exitformSchool () {
      this.updateschool = false
      this.msg = undefined
      this.idschool = undefined
      this.dialogEditschool = false
    }
  }
}
</script>
