<template>
  <div>
    <ItemPage
      title="Cadastro do usuário"
      size="sm"
      :actions="actions"
    >
      <v-tabs
        v-model="tab"
        background-color="white"
        fixed-tabs
        show-arrows
        icons-and-text
      >
        <v-tabs-slider />
        <v-tab href="#dados">
          Dados do usuário
          <v-icon>mdi-account-box</v-icon>
        </v-tab>
        <v-tab href="#roles">
          Cargos - Permissões
          <v-icon>mdi-account-details</v-icon>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item id="dados">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.name"
                  :disabled="!intranetUserUpdate"
                  :rules="rulesName"
                  :error-messages="errors.name"
                  label="Nome"
                  @input="verifyUpdate()"
                />
                <v-text-field
                  v-model="user.email"
                  :disabled="!intranetUserUpdate"
                  :rules="rulesEmail"
                  :error-messages="errors.email"
                  label="E-mail"
                  @input="verifyUpdate()"
                />
                <v-text-field
                  v-model="password"
                  :disabled="!intranetUserUpdate"
                  :rules="rulesPassword"
                  :error-messages="errors.password"
                  label="Senha"
                  type="password"
                  @input="verifyUpdate()"
                />
                <v-switch
                  v-model="user.status"
                  :disabled="!intranetUserUpdate"
                  class="ma-2"
                  :label="user.status ? 'Ativo' : 'Inativo'"
                  @change="verifyUpdate"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item id="roles">
          <v-container>
            <v-row>
              <v-col>
                <div
                  v-for="role in roles"
                  :key="role.id"
                >
                  <v-switch
                    v-model="user.intranet_roles"
                    :disabled="!intranetUserUpdate"
                    color="primary"
                    :label="role.display_name"
                    :value="role.id"
                    @change="verifyUpdate()"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </ItemPage>
    <v-dialog
      v-model="loading"
      width="500"
      persistent
    >
      <Load
        :loadingmsg="loadingMsg"
      />
    </v-dialog>
  </div>
</template>

<script>
import Load from '@/components/Loading.vue'
import ItemPage from '@/components/pages/ItemPage.vue'
import utils from '@/api/utils.js'

export default {
  components: {
    Load,
    ItemPage
  },
  data () {
    return {
      tab: 'dados',
      rulesName: [
        value => !!value || 'Nome é obrigatório.',
        value => (value && value.length >= 1) || 'Min 1 caracteres'
      ],
      rulesEmail: [
        v => !!v || 'E-mail é obrigatório',
        v => /.+@.+\..+/.test(v) || 'E-mail inválido'
      ],
      rulesPassword: [
        value => !value || (utils.size(value) >= 8) || 'Min 8 caracteres'
      ],
      user: [],
      roles: {},
      password: null,
      currentUser: [],
      loading: false,
      loadingMsg: '',
      disableUpdate: true,
      intranetUserUpdate: true,
      errors: [],
      myUser: {},
      godAdmin: false
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },

    actions () {
      return [
        {
          label: 'Salvar alterações',
          action: this.updateUser,
          icon: 'mdi-floppy',
          disabled: this.isDisabled()
        }
      ]
    }
  },
  created () {
    this.fetchAll()
  },
  methods: {
    isDisabled () {
      if (!this.intranetUserUpdate) {
        return true
      }
      if (this.disableUpdate) {
        return true
      }
      return false
    },
    async fetchAll () {
      this.intranetUserUpdate = utils.checkPermissions('intranet-users.update-any')
      await this.getUserId()
      await this.getRoles()
    },
    getErrors (data) {
      if (data.data.errors) {
        this.errors = utils.setErrorsData(data.data.errors)
      } else {
        throw (data)
      }
    },
    async getUserId () {
      this.loading = true
      this.loadingMsg = 'Carregando informações'
      try {
        const response = await this.$api.getUserId(this.id)
        this.user = response
        this.user.intranet_roles = response.intranet_roles.map(function permission (per) { return per.id })
        if (this.user.status === 1) {
          this.user.status = true
        } else {
          this.user.status = false
        }
        this.currentUser = utils.currentData(this.user)
      } catch (e) {
        this.getErrors(e.response)
      }
      this.loading = false
    },
    async getRoles () {
      try {
        this.roles = await this.$api.getRoles()
      } catch (e) {
        this.getErrors(e.response)
      }
    },
    verifyUpdate () {
      const update = utils.diffObjects(this.user, this.currentUser)
      if (Object.keys(update).length > 0 || this.password) {
        this.disableUpdate = false
      } else {
        this.disableUpdate = true
      }
    },
    async updateUser () {
      const data = utils.diffObjects(this.user, this.currentUser)
      if (this.password) {
        data.password = this.password
      }
      this.loading = true
      this.loadingMsg = 'Salvando informações'
      try {
        await this.$api.updateUser(data, this.id)
        if (data.intranet_roles) {
          const roles = {
            intranet_roles: data.intranet_roles
          }
          await this.$api.syncRoles(this.id, roles)
        }
        this.getUserId()
        this.disableUpdate = true
        this.$store.dispatch('setSnackBar', { text: 'Usuário atualizado' })
      } catch (e) {
        this.getErrors(e.response)
      }
      this.loading = false
    }
  }
}
</script>
