<template>
  <div>
    <div class="d-flex flex-column">
      <div class="d-inline-flex">
        <v-text-field
          :value="maintainer.name"
          label="Nome"
          disabled
          class="mr-2"
        />
        <!-- <v-btn
          class="mt-2 mb-10"
          depressed
          large
          @click="goToUser(maintainer.id)"
        >
          <v-icon left>
            mdi-pencil
          </v-icon> Editar usuário
        </v-btn> -->
      </div>
      <div class="d-inline-flex">
        <v-text-field
          :value="maintainer.email"
          label="E-mail"
          disabled
          class="mr-2"
        />
        <template>
          <v-btn
            :disabled="loadPass"
            filled
            class="mt-1"
            depressed
            large
            @click="dialogConfirm = true"
          >
            <v-icon left>
              mdi-lock-plus
            </v-icon> {{ loadPass === true ? 'Enviando...' : msgButontempPass }}
          </v-btn>
        </template>
      </div>
      <v-text-field
        :value="maintainer.phone ? maintainer.phone : 'não informado'"
        label="Telefone"
        disabled
      />
    </div>
    <v-dialog
      v-model="dialogConfirm"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title>
          Enviar nova senha temporária
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
          >
            Tem certeza que deseja enviar nova senha para este usuário ?
          </v-form>
        </v-card-text>
        <v-card-text>
          <div class="d-flex justify-space-between">
            <v-btn
              color="primary"
              text
              @click="dialogConfirm = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="newTemporaryPass(maintainer.id)"
            >
              Enviar
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    maintainer: { type: Object, default: undefined }
  },

  data: () => ({
    dialogConfirm: false,
    loadPass: false,
    msgButontempPass: 'Enviar nova senha',
    showInfo: true
  }),
  created () {
  },
  methods: {
    async newTemporaryPass (id) {
      this.loadPass = true
      this.dialogConfirm = false
      try {
        await this.$api.resetPasswordMaintainer(id)
        this.loadPass = false
        this.msgButontempPass = 'Senha enviada'
      } catch (e) {
        this.loadPass = false
        this.msgButontempPass = 'Tente novamente'
        // console.log(e.response)
      }
    },
    goToUser (userId) {
      this.$router.push({ name: 'portal-user', params: { id: userId } })
    }
  }
}
</script>
