import axios from 'axios'

// Intranet users

export async function getUser () {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/intranet-users')
  return response.data.data
}

export async function getUserId (id) {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/intranet-users/' + id + '?with=intranet_roles,intranet_permissions')
  return response.data.data
}

export async function newUser (data) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/intranet-users', data)
  return response.data.data
}

export async function syncRoles (id, data) {
  const response = await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/intranet-users/' + id + '/sync-roles', data)
  return response.data.data
}

export async function updateUser (data, id) {
  await axios.put(import.meta.env.VITE_API_URL + '/v1/intranet/intranet-users/' + id, data)
}

export async function sendAvatar (formData, id) {
  await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/intranet-users/' + id + '/avatar', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export async function deleteAvatar (id) {
  await axios.delete(import.meta.env.VITE_API_URL + '/v1/intranet/intranet-users/' + id + '/avatar')
}

export default {
  getUser,
  getUserId,
  newUser,
  syncRoles,
  updateUser,
  sendAvatar,
  deleteAvatar
}
