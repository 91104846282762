<template>
  <div class="mt-10">
    {{ levelgroupname }}
    <div
      v-for="gradeLevel in levelgroups.grade_levels"
      :key="gradeLevel.id"
    >
      <ItemContract
        :grade-level="gradeLevel"
        :contractid="contractid"
        :system="system"
        :items="itemsOfContract"
        @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>
import ItemContract from '@/components/schools/contracts/Contract_Item.vue'

export default {
  components: {
    ItemContract
  },
  props: {
    contractid: { type: Number, default: 0 },
    system: { type: Number, default: 0 },
    levelgroups: { type: Object, default: () => {} },
    items: { type: Array, default: () => [] },
    levelgroupname: { type: String, default: '' }
  },
  data: () => ({
    checkbox: false,
    itemsOfContract: {}
  }),
  watch: {
    items () {
      this.getItemsOfContract()
    }
  },
  created () {
    this.getItemsOfContract()
  },
  methods: {
    async getItemsOfContract () {
      const response = this.items.filter((item) => {
        if (item.teaching_system_id === this.system && this.findLevel(item.grade_level_id) === true) {
          return item
        }
      })
      this.itemsOfContract = response
    },
    findLevel (id) {
      const response = this.levelgroups.grade_levels.find((item) => item.id === id)
      if (response) {
        return true
      }
      return false
    }
  }
}
</script>
