<template>
  <v-card>
    <v-progress-linear
      color="amber"
      indeterminate
    />
    <div
      v-if="loadingmsg"
      class="text-center"
    >
      <span style="color: grey">{{ loadingmsg }}</span>
    </div>
  </v-card>
</template>

<script>

export default {
  name: 'Loading',

  props: {
    loadingmsg: { type: String, default: undefined }
  }
}
</script>
