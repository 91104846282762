import axios from 'axios'
import utils from '@/api/utils.js'

// Auth
export function checkLogin () {
  if (!localStorage.getItem('currentUser')) {
    return false
  }
  return true
}

export function setError (data) {
  localStorage.setItem('Error', data)
  // store.commit('setError', data)
}

export function getError () {
  if (utils.size(localStorage.getItem('Error')) > 0) {
    return false
  }
}

export async function getCookie (data) {
  try {
    await axios.get(import.meta.env.VITE_API_URL + '/csrf-cookie')
    await this.loginIntranet(data)
  } catch {
    this.setError('Erro: Não foi possível efetuar o login (motivo: problema com o cookie)')
    return false
  }
}

export async function loginIntranet (data) {
  try {
    await axios.post(import.meta.env.VITE_API_URL + '/v1/intranet/auth/login', data)
    await this.me()
  } catch (e) {
    if (e.response.data.errors.email) {
      this.setError(e.response.data.errors.email)
    } else {
      this.setError('Erro ao efeutar login: tente novamente mais tarde')
    }
    return false
  }
}

export async function me () {
  try {
    const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/auth/me')
    localStorage.clear()
    utils.setUser(response.data.data)
    utils.setPermissions(response.data.data.intranet_permissions)
  } catch {
    localStorage.clear()
    this.setError('Não foi possível obter os dados do usuário')
    return false
  }
}

export async function logout () {
  try {
    await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/auth/logout')
  } catch {
    // console.log('erro ao deslogar')
  }
  localStorage.clear()
}

export async function getPermissions () {
  const response = await axios.get(import.meta.env.VITE_API_URL + '/v1/intranet/intranet-permissions')
  return response.data.data
}

export default {
  checkLogin,
  setError,
  getError,
  getCookie,
  loginIntranet,
  me,
  logout,
  getPermissions
}
