<template>
  <div>
    <div class="row grey lighten-5 ml-2">
      <div class="col-auto d-flex align-center py-0">
        {{ gradeLevel.name }}
      </div>

      <div class="col-auto d-flex flex-wrap">
        <div class="d-flex align-center ml-1  py-0">
          <v-checkbox
            v-model="itemsToContract.has_textbooks"
            :disabled="disableForUpdate"
            @change="checkUpdate"
          />
          Material didático
          <v-select
            v-if="itemsToContract.has_textbooks"
            v-model="itemsToContract.publishing_year"
            :disabled="disableForUpdate"
            :items="years"
            class="ml-5"
            style="max-width: 120px;"
            label="Ano de publicação"
            @change="checkUpdate"
          />
          <v-select
            v-if="itemsToContract.has_textbooks"
            v-model="itemsToContract.publishing_year_additional"
            :disabled="disableForUpdate"
            :items="years"
            class="ml-5"
            style="max-width: 120px;"
            label="Complementares"
            @change="checkUpdate"
          />
        </div>
      </div>

      <div class="col d-flex align-center justify-end">
        <v-btn
          v-if="saveBtn"
          color="success"
          class=""
          :disabled="disableForUpdate"
          text
          @click="IncludeItem"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          adicionar item
        </v-btn>
        <v-btn
          v-if="enableDelete"
          :disabled="disableForUpdate"
          color="primary"
          text
          @click="modaldelete = true"
        >
          <v-icon left>
            mdi-delete
          </v-icon>
          excluir item
        </v-btn>
        <v-btn
          v-if="updateBtn"
          :disabled="disableForUpdate"
          color="red"
          @click="updateItem"
        >
          salvar
        </v-btn>
      </div>
      <v-dialog
        id="modal-delete"
        v-model="modaldelete"
        color="white"
        width="500"
      >
        <v-card>
          <v-card-title>
            Excluir item do contrato ({{ gradeLevel.name }})
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
            >
              Tem certeza que deseja excluir <b> {{ gradeLevel.name }} </b> ?
            </v-form>
          </v-card-text>
          <v-card-text>
            <div class="d-flex justify-space-between">
              <v-btn
                color="primary"
                text
                @click="modaldelete = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="excludeItem"
              >
                Excluir
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    contractid: { type: Number, default: 0 },
    items: { type: Array, default: () => [] },
    gradeLevel: { type: Object, default: () => {} },
    system: { type: Number, required: true }
  },

  data: () => ({
    modaldelete: false,
    itemsToContract: {
      teaching_system_id: null,
      grade_level_id: null,
      publishing_year: new Date().getFullYear(),
      publishing_year_additional: new Date().getFullYear(),
      has_textbooks: 1
    },
    publishing_year: new Date().getFullYear(),
    publishing_year_additional: new Date().getFullYear(),
    years: [
      new Date().getFullYear() + 1,
      new Date().getFullYear(),
      new Date().getFullYear() - 1,
      new Date().getFullYear() - 2,
      new Date().getFullYear() - 3
    ],
    disableForUpdate: false,
    hasitem: false,
    saveBtn: false,
    updateBtn: false,
    enableDelete: false,
    itemOfContract: {}
  }),
  watch: {
    items () {
      this.itemsToContract.teaching_system_id = this.system
      this.itemsToContract.grade_level_id = this.gradeLevel.id
      this.findItem()
    },
    updateBtn () {
      if (this.updateBtn === true) {
        this.enableDelete = false
      } else {
        this.enableDelete = true
      }
    },
    saveBtn () {
      if (this.saveBtn === true) {
        this.enableDelete = false
        this.updateBtn = false
      }
    },
    hasitem () {
      if (this.hasitem === true) {
        this.enableDelete = true
      } else {
        this.enableDelete = false
        this.saveBtn = true
      }
    },
    enableDelete () {
      if (this.enableDelete === true) {
        this.saveBtn = false
        this.updateBtn = false
      }
    }
  },
  created () {
    this.itemsToContract.teaching_system_id = this.system
    this.itemsToContract.grade_level_id = this.gradeLevel.id
    this.findItem()
  },
  methods: {
    findItem () {
      this.disableForUpdate = false
      this.itemOfContract = this.items.find((item) => item.grade_level_id === this.gradeLevel.id) ?? {}
      if (Object.keys(this.itemOfContract).length > 0) {
        this.itemsToContract.has_textbooks = this.itemOfContract.has_textbooks
        this.itemsToContract.publishing_year = this.itemOfContract.publishing_year
        this.itemsToContract.publishing_year_additional = this.itemOfContract.publishing_year_additional
        this.hasitem = true
        this.enableDelete = true
      } else {
        this.hasitem = false
        this.saveBtn = true
      }
    },

    async excludeItem () {
      this.modaldelete = false
      try {
        this.disableForUpdate = true
        await this.$api.deleteContractItem(this.itemOfContract.id)
      } catch (e) {
        this.disableForUpdate = false
        this.getErrors(e)
      }
      this.$store.dispatch('setSnackBar', { text: 'Item excluido do contrato' })
      this.updateContract()
    },
    checkUpdate () {
      if (this.hasitem === false) {
        return
      }
      this.itemsToContract.has_textbooks = this.itemsToContract.has_textbooks ? 1 : 0
      const allItems = []
      Object.entries(this.itemsToContract).forEach(([key]) => {
        allItems.push(this.itemsToContract[key] === this.itemOfContract[key])
      })
      if (!allItems.every(item => item === true)) {
        this.updateBtn = true
      } else {
        this.updateBtn = false
      }
    },

    async updateItem () {
      const data = {
        teaching_system_id: this.itemsToContract.teaching_system_id,
        publishing_year: this.itemsToContract.publishing_year,
        publishing_year_additional: this.itemsToContract.publishing_year_additional,
        grade_level_id: this.itemsToContract.grade_level_id,
        has_textbooks: this.itemsToContract.has_textbooks
      }
      try {
        this.disableForUpdate = true
        await this.$api.updateContractItem(this.itemOfContract.id, data)
      } catch (e) {
        this.disableForUpdate = false
        this.getErrors(e)
      }
      this.$store.dispatch('setSnackBar', { text: 'Item do contrato atualizado' })
      this.updateContract()
    },

    async IncludeItem () {
      const data = {
        teaching_system_id: this.itemsToContract.teaching_system_id,
        publishing_year: this.itemsToContract.publishing_year,
        publishing_year_additional: this.itemsToContract.publishing_year_additional,
        grade_level_id: this.itemsToContract.grade_level_id,
        has_textbooks: this.itemsToContract.has_textbooks
      }
      try {
        this.disableForUpdate = true
        await this.$api.newContractItem(this.contractid, data)
      } catch (e) {
        this.disableForUpdate = false
        this.getErrors(e)
      }
      this.$store.dispatch('setSnackBar', { text: 'Item incluido no contrato' })
      this.updateContract()
    },

    updateContract () {
      this.$emit('reload')
    }
  }
}
</script>
